import React from 'react'
import { Link } from 'react-router-dom'

const Company_Box = ({ company_name, id, company_size, profile_pic, country }) => {
    return (
        <div className="company-listing-item">
            <div className="compny-top-sec">
                <div className="company-logo-detail">
                    <div className="compny-logo">
                        {profile_pic == null ? <img src="images/company-logo-05.png" /> : <img src={`https://works-wise.demo-customproject.com/admin/backend/public/uploads/users/${profile_pic}`} alt />}
                    </div>
                    <div className="company-ver-badge">
                        <img src="images/verified-badge.png" alt />
                    </div>
                </div>
                <div className="company-reviews">
                    <i className="fas fa-star" aria-hidden="true" /> <span>4.5K</span>
                </div>
            </div>
            <Link to={`/company-profile/${id}`}>
                <h3>{company_name}</h3>
            </Link>
            <ul>
                <li className="comp-loca"><span className="comp-loca-hd">Location: </span>{country}
                </li>
                <li className="emp-count"><span className="emp-count-hd">Employees: </span>{company_size} Employees</li>
                <li className="total-jobs-posted"><span className="ttl-jb-post-hd">Total Jobs
                    Posted: </span> 120 Job Posted
                </li>
                <li className="active-jobs"><span>Active Jobs: </span>10 Jobs</li>
            </ul>
            <div className="view-more-jobs">
                <p>View more jobs and detail</p>
                <Link to={`/company-profile/${id}`}>View More</Link>
            </div>
            <div className>
            </div>
        </div>
    )
}

export default Company_Box