import React, { useEffect } from 'react'
import $ from 'jquery'
import { Link } from 'react-router-dom';
import { useProductContext } from '../context/productcontext';

const Footer = () => {
    const { logos } = useProductContext();
    useEffect(() => {
        const toggleChatboxBtn = document.querySelector(".js-chatbox-toggle");
        const chatbox = document.querySelector(".js-chatbox");
        const chatboxMsgDisplay = document.querySelector(".js-chatbox-display");
        const chatboxForm = document.querySelector(".js-chatbox-form");
        const createChatBubble = (input) => {
            const chatSection = document.createElement("p");
            chatSection.textContent = input;
            chatSection.classList.add("chatbox__display-chat");
            chatboxMsgDisplay.appendChild(chatSection);
        };
        toggleChatboxBtn.addEventListener("click", () => {
            chatbox.classList.toggle("chatbox--is-visible");
        });
        chatboxForm.addEventListener("submit", (e) => {
            const chatInput = document.querySelector(".js-chatbox-input").value;

            createChatBubble(chatInput);

            e.preventDefault();
            chatboxForm.reset();
        });
        const button = document.querySelector(".scroll-btn");
        const scrollToTop = () => {
            button.addEventListener("click", () => {
                window.scroll({
                    top: 0,
                    left: 0,
                    behavior: "smooth",
                });
            });
        };
        scrollToTop();

        $(".live-chats").click(function () {
            $(".live-chats").toggleClass("live-chat-visible");
        });
    })
    return (
        <>
            <div className="footerSec pt-10">
                <div className="scroll-top">
                    <button className="scroll-btn"><i className="fas fa-arrow-up" /></button>
                </div>
                <div className="footer-top">
                    <div className="footer-outer">
                        <img src="images/banner-bottom.png" alt />
                    </div>
                    <div className="container">
                        <div className="row align-items-end">
                            <div className="col-lg-3">
                                <div className="footer-logo">
                                    {logos && <img src={`https://works-wise.demo-customproject.com/admin/backend/public/uploads/${logos[2]?.img_path}`} alt />}
                                </div>
                            </div>
                            <div className="col-lg-9">
                                <div className="footer-menu">
                                    <ul>
                                        <li><Link to="/">Home</Link></li>
                                        <li><Link to="/about">About Us</Link></li>
                                        <li><Link to="/find-jobs">Find Jobs</Link></li>
                                        <li><Link to="/find-talent">Find Talent</Link></li>
                                        <li><Link to="/pricing">Pricing</Link></li>
                                        <li><Link to="/contact">Contact Us</Link></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footer-btm">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6">
                                <div className="copy-txt">
                                    <p>© 2023 Work Wise. All Right Reserved.</p>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="term-policy text-end">
                                    <a href="#">Terms And Condition</a>
                                    <a href="#">Privacy Policy</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <section className="chatbox js-chatbox">
                <div className="chatbox__header">
                    <button className="js-chatbox-toggle chatbox__header-cta-btn u-btn">
                        <div className="live-chats">
                            <div className="live-chat-btn">
                                <p>Live Chat</p>
                            </div>
                            <div className="msg-btn">
                                <i className="far fa-comments" />
                            </div>
                        </div>
                    </button>
                </div>
                <div className="js-chatbox-display chatbox__display">
                </div>
                <form className="js-chatbox-form chatbox__form">
                    <input type="text" className="js-chatbox-input chatbox__form-input" placeholder="Type your message..." required />
                    <button className="chatbox__form-submit u-btn"><i className="fas fa-paper-plane" /></button>
                </form>
            </section>
        </>
    )
}

export default Footer