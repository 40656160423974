import React from 'react'
import axios from 'axios'
import { useEffect } from 'react'
import { useState } from 'react';

function Benefits() {


    const [cms, cmsData] = useState(null);

    const getData = async () => {
        await axios.get('https://works-wise.demo-customproject.com:4002/get-cms/benefits_section').then((res) => {
            cmsData(res.data);
        })
    }

    useEffect(() => {
        getData();
    }, [])

    if (cms === null) {
        return <div className='Loading'></div>
    }

    return (
        <div>
            <section className="benefits-of-workwise pb-10">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-8">
                            <div className="sec-heading text-center">
                                <h2>{cms.benefits_section.title}</h2>
                            </div>
                        </div>
                    </div>{/* row end */}
                    <div className="row mb-none-30 mt-5">
                        <div className="col-lg-3 col-md-4 mb-30 wow fadeInUp text-md-left text-center" data-wow-duration="0.3s" data-wow-delay="0.3s">
                            <div className="feature-card">
                                <div className="feature-card__icon"><i className="fad fa-search" /></div>
                                <div className="feature-card__content">
                                    <h4 className="title">{cms.benefits_section.text1}</h4>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-4 mb-30 wow fadeInUp text-md-left text-center" data-wow-duration="0.3s" data-wow-delay="0.3s">
                            <div className="feature-card">
                                <div className="feature-card__icon"><i className="fad fa-handshake" /></div>
                                <div className="feature-card__content">
                                    <h4 className="title">{cms.benefits_section.text2}</h4>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-4 mb-30 wow fadeInUp text-md-left text-center" data-wow-duration="0.3s" data-wow-delay="0.3s">
                            <div className="feature-card">
                                <div className="feature-card__icon"><i className="fad fa-bullhorn" /></div>
                                <div className="feature-card__content">
                                    <h4 className="title">{cms.benefits_section.text3}</h4>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-4 mb-30 wow fadeInUp text-md-left text-center" data-wow-duration="0.3s" data-wow-delay="0.3s">
                            <div className="feature-card">
                                <div className="feature-card__icon"><i className="fad fa-suitcase" /></div>
                                <div className="feature-card__content">
                                    <h4 className="title">{cms.benefits_section.text4}</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default Benefits
