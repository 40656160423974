import React from 'react'
import { useState } from 'react';
import { useEffect } from 'react';
import { Link } from 'react-router-dom'

function Candidate_box({ profile_pic, first_name, last_name, hourly_rate, currency, skills, id, designation }) {
    const [a, seta] = useState(1);
    useEffect(() => {
        setTimeout(function () {
            seta(0);
        }, 1000)
    }, [])
    if (a === 1) {
        return <div className='product-item-mew prd-box-load'></div>
    }

    return (
        <div>
            <div className="cndidte-list-item">
                {/* <div className="ring-container">
                    <div className="ringring" />
                    <div className="online" />
                </div> */}
                <div className="user-rate">
                    {
                        profile_pic === null ? <div className='profile_namekey'>{first_name[0]}</div> : <img src={`https://works-wise.demo-customproject.com/admin/backend/public/uploads/users/${profile_pic}`} alt />
                    }

                    <div className="user-tags">
                        <ul className="candi-rating">
                            <li className="candidate-availablity"><span>Available</span></li>
                        </ul>
                    </div>
                </div>
                <Link to={`/candidate-profile/${id}`}>
                    <h4>{first_name} {last_name}</h4>
                </Link>
                <div className='designation-box'>{designation}</div>
                <ul className="candidate-info">
                    {
                        hourly_rate === null ? <li className="perhr-price">Price Per Hrs: <span>30 <sub>{currency}</sub> - 40<sub>{currency}</sub></span></li> : <li className="perhr-price">Price Per Hrs: <span>30<sub>{currency}</sub> - 40<sub>{currency}</sub></span></li>
                    }
                    {/* <li className="candidate-review"><i className="fas fa-star" /> <span className="review-num">45</span> Reviews</li> */}
                </ul>
                <div className='skills-wrap'>
                    {
                        skills.split(',').map((skl) => {
                            return <span>{skl}</span>
                        })
                    }
                </div>
                {/* <p className="candidate-short-desc">{introduce}</p> */}

                <div className="hire-btn">
                    <Link to={`/candidate-profile/${id}`}className="hire-candidate">View Profile</Link>
                </div>
            </div>
        </div>
    )
}

export default Candidate_box
