import React from 'react'

const TimeAgo = (props) => {
    function timeAgo(dateString) {
        const currentDate = new Date();
        const pastDate = new Date(dateString);

        const seconds = Math.floor((currentDate - pastDate) / 1000);
        const minutes = Math.floor(seconds / 60);
        const hours = Math.floor(minutes / 60);
        const days = Math.floor(hours / 24);

        if (days > 0) {
            return days === 1 ? '1 day ago' : `${days} days ago`;
        } else if (hours > 0) {
            return hours === 1 ? '1 hour ago' : `${hours} hours ago`;
        } else if (minutes > 0) {
            return minutes === 1 ? '1 minute ago' : `${minutes} minutes ago`;
        } else {
            return seconds === 1 ? '1 second ago' : `${seconds} seconds ago`;
        }
    }
    return timeAgo(props.date)
}

export default TimeAgo