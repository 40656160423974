import React from 'react'
import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import secureLocalStorage from 'react-secure-storage';
import $ from 'jquery';
import { useProductContext } from '../context/productcontext';

const Header = () => {
    const { logos } = useProductContext();
    useEffect(() => {
        $('.header-logo').click(function () {
            $('#menu > li').removeClass('active')
        })
    })
    return (
        <>
            <header>
                <div className="topSec menuSec">
                    <div className="container">
                        <div className="row d-flex">
                            <div className="col-lg-2 col-md-2 col-sm-6 wow fadeInLeft">
                                <div className="top-bar-left">
                                    <div className="header-logo">
                                        {logos && <Link to="/"><img src={`https://works-wise.demo-customproject.com/admin/backend/public/uploads/${logos[0]?.img_path}`} alt="img" /></Link>}
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-10 col-lg-7 d-none d-md-block">
                                <ul id="menu">
                                    {/* <li><Link to="/">Home</Link></li> */}
                                    <li><Link to="/about">About Us</Link></li>
                                    <li><Link to="/find-jobs">Find Jobs</Link></li>
                                    <li>
                                        <Link to="/find-talent" className="dropdown-click">Find Talent<i className="fas fa-chevron-down" /></Link>
                                        <ul className="dropdown">
                                            {/* <li><Link to="/#">Job Posting</Link></li> */}
                                            <li><Link to="/candidate-listing">Talent Search</Link></li>
                                            {/* <li><Link to="/#">Project Management</Link></li> */}
                                            <li><Link to="/job-listing">Job Listing</Link></li>
                                            <li><Link to="/company-listing">Company Listing</Link></li>
                                        </ul>
                                    </li>
                                    <li><Link to="/pricing">Pricing</Link></li>
                                    <li><Link to="/contact">Contact Us</Link></li>
                                </ul>
                            </div>
                            <div className="col-md-5 col-lg-3 col-sm-6 centerCol">
                                {
                                    secureLocalStorage.getItem('login_data') === null ? <div className="top-bar-right wow fadeInRight">
                                        <div className="talent-login">
                                            <Link to="/login" className="btn1"><span>Login</span></Link>
                                        </div>
                                        <div className="emp-login">
                                            <Link to="/sign-up" className="btn1"><span>Register</span></Link>
                                        </div>
                                    </div> : <div className="top-bar-right wow fadeInRight">
                                        <div className="talent-login dashbb">
                                            <a href="/dashboard/" className="btn1"><span>Go To Dashboard</span></a>
                                        </div>
                                    </div>
                                }

                            </div>
                        </div>
                    </div>
                </div>
            </header>

        </>
    )
}

export default Header
