import React, { useEffect, useState } from 'react';
import axios from 'axios';
import $ from 'jquery';
import { omit } from 'lodash'

const Reset_Password = () => {
    if (localStorage.getItem('login_data') == null) {
        window.location.replace('/login');
    }
    const id = JSON.parse(localStorage.getItem('login_data')).id;
    const [data, setData] = useState({});
    const [errors, setErrors] = useState({});
    const setVal = (e) => {
        setData((prev) => ({ ...prev, [e.target.name]: e.target.value }));
        validate(e.target.name, e.target.value);
    }
    const validate = (name, value) => {
        switch (name) {
            case 'password':
                if (
                    !new RegExp(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$/).test(value)
                ) {
                    setErrors({
                        ...errors,
                        password: 'Password should contains atleast 8 charaters and containing uppercase,lowercase and numbers'
                    })
                } else {

                    let newObj = omit(errors, "password");
                    setErrors(newObj);

                }
                break;
            case 'confirm_pass':
                if (value !== data.password) {
                    setErrors({
                        ...errors,
                        confirm_pass: "Password does not match"
                    })
                } else {
                    let newObj = omit(errors, "confirm_pass");
                    setErrors(newObj);
                }
                break;

            default:
                break;
        }
    }

    useEffect(() => {
        $('.show-pass').unbind().click(function () {
            if ($(this).hasClass('sho')) {
                $(this).prev().attr('type', 'password');
                $(this).removeClass('sho');
            } else {
                $(this).prev().attr('type', 'text');
                $(this).addClass('sho');
            }
        })
    })

    const resetPass = async (e) => {
        e.preventDefault();
        $('input').each(function () {
            if ($(this).val() == '') {
                $(this).addClass('err')
            } else {
                $(this).removeClass('err')
            }
        })
        if (data.password === $('#confirm_pass').val()) {
            await axios.post('https://beeta.shica.io:8448/reset-password', { ...data, id: id }).then(function (response) {
                if (response.data.status == '200') {
                    $('.rsst .msg-box').removeClass('red')
                    $('.rsst .msg-box').addClass('green').text(response.data.message);
                    setData({});
                    $('.aut-form-box input').val('');
                } else {
                    $('.rsst .msg-box').removeClass('green')
                    $('.rsst .msg-box').addClass('red').text(response.data.message);
                }
            }).catch(function (error) {
                console.log(error);
            });
        } else {
            $('#confirm_pass').addClass('err');
        }
    }
    return (
        <>
            <h3 className='text-center'>Reset Password</h3>
            <div className="row align-items-center">
                <div className="col-lg-12 col-md-12 centerCol">
                    <div className="auth-head">
                        <div className="aut-form-box rsst">
                            <form action="#">
                                <div className="row">
                                    <div className="col-lg-12 col-md-12">
                                        <div className="auth-form position-relative">
                                            <input type="password" className='mb-0' onChange={setVal} name="password_old" placeholder="Enter Old Password" />
                                            <span className="show-pass"><i className="fa-sharp fa-regular fa-eye"></i><i className="fa-sharp fa-regular fa-eye-slash d-none"></i></span>
                                        </div>
                                    </div>
                                    <div className="col-lg-12 col-md-12">
                                        <div className="auth-form position-relative">
                                            <input type="password" className='mb-0' onChange={setVal} name="password" placeholder="Enter New Password" />
                                            <span className="show-pass"><i className="fa-sharp fa-regular fa-eye"></i><i className="fa-sharp fa-regular fa-eye-slash d-none"></i></span>
                                        </div>
                                        {errors.password ? <div className='errorr'>{errors.password}</div> : false}
                                    </div>
                                    <div className="col-lg-12 col-md-12">
                                        <div className="auth-form position-relative">
                                            <input type="password" className='mb-0' onChange={setVal} name="confirm_pass" id="confirm_pass" placeholder="Confirm Password" />
                                            <span className="show-pass"><i className="fa-sharp fa-regular fa-eye"></i><i className="fa-sharp fa-regular fa-eye-slash d-none"></i></span>
                                        </div>
                                        {errors.confirm_pass ? <div className='errorr'>{errors.confirm_pass}</div> : false}
                                    </div>
                                    <div className="col-lg-6 col-md-6 m-auto mt-4">
                                        <div className="auth-btn">
                                            <button className='w-100' onClick={resetPass}> Update Password</button>
                                        </div>
                                    </div>
                                    <div className='msg-box'></div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default Reset_Password