import React from 'react'
import axios from 'axios';
import $ from 'jquery';
import { Link, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import secureLocalStorage from 'react-secure-storage';
import { omit } from 'lodash'


const Update_Password = () => {
    let id;
    const navigate = useNavigate();
    const [errors, setErrors] = useState({});
    const [user, setUser] = useState({
        password_confirm: "",
        password: "",
    });
    useEffect(() => {
        if (secureLocalStorage.getItem('login_data') == null) {
            navigate('/login')
        } else {
            id = JSON.parse(secureLocalStorage.getItem('login_data')).id;
        }
    })
    const setVal = (e) => {
        setUser((prev) => ({ ...prev, id: id }));
        setUser((prev) => ({ ...prev, [e.target.name]: e.target.value }));
        validate(e.target.name, e.target.value);
    }

    const validate = (name, value) => {
        switch (name) {
            case 'password':
                if (
                    !new RegExp(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$/).test(value)
                ) {
                    setErrors({
                        ...errors,
                        password: 'Password should contains atleast 8 charaters and containing uppercase,lowercase and numbers'
                    })
                } else {

                    let newObj = omit(errors, "password");
                    setErrors(newObj);

                }
                break;
            case 'password_confirm':
                if (value !== user.password) {
                    setErrors({
                        ...errors,
                        password_confirm: "Password does not match"
                    })
                } else {
                    let newObj = omit(errors, "password_confirm");
                    setErrors(newObj);
                }
                break;

            default:
                break;
        }
    }
    let err = {};
    const validate2 = (name, value) => {
        if (name == 'password') {
            if (
                !new RegExp(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$/).test(value)
            ) {
                err = {
                    ...err,
                    password: 'Password should contains atleast 8 charaters and containing uppercase,lowercase and numbers'
                }
            } else {

                let newObj = omit(err, "password");
                err = newObj;

            }
        }
        if (name == 'password_confirm') {
            if (value !== user.password) {
                err = {
                    ...err,
                    password_confirm: "Password does not match"
                }
            } else {
                let newObj = omit(err, "password_confirm");
                err = newObj;
            }
        }
        setErrors(err);
    }



    const ChangePass = async (e) => {
        e.preventDefault();
        Object.keys(user).map((item) => {
            validate2(item, user[item]);
        })
        console.log(user, errors)
        if (Object.keys(errors).length === 0 && Object.keys(err).length === 0) {
            $('#confirm-pass').removeClass('err');
            await axios.post('https://works-wise.demo-customproject.com:4002/forgot-password-update', user).then(function (response) {
                if (response.data.status == '200') {
                    $('.msg-box').removeClass('red')
                    $('.msg-box').addClass('green').text(response.data.message);
                    setUser({});
                    $('.aut-form-box input').val('');
                    setTimeout(function () {
                        window.location.href = '/dashboard/'
                    }, 1000)
                } else {
                    $('.msg-box').removeClass('green')
                    $('.msg-box').addClass('red').text(response.data.message);
                }
            }).catch(function (error) {
                console.log(error);
            });
        }
    }


    useEffect(() => {
        $(".pasWord ~ i").unbind().click(function () {
            $(this).toggleClass("fa-eye fa-eye-slash");
            let inPut = $(this).parent().find('.pasWord');
            if (inPut.attr('type') === 'password') {
                inPut.attr("type", "text");
            } else {
                inPut.attr("type", "password");
            }
        })
    })

    return (
        <section className="aouther-form-page">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6 col-md-12 centerCol">
                        <div className="auth-head">
                            <h2>Update Password</h2>
                            <div className="aut-form-box signupp_box">
                                <form action="#">
                                    <div className="row">
                                        <div className="col-lg-12 col-md-12">
                                            <div className="auth-form form">
                                                <input type="password" name="password" onChange={setVal} className='input pasWord' placeholder="Enter New Password" />
                                                <i class="far fa-eye-slash"></i>
                                                <span className="input-border"></span>
                                            </div>
                                            {errors.password ? <div className='errorr'>{errors.password}</div> : false}
                                        </div>
                                        <div className="col-lg-12 col-md-12">
                                            <div className="auth-form form">
                                                <input type="password" name="password_confirm" onChange={setVal} id="confirm-pass" className='input pasWord' placeholder="Confirm Password" />
                                                <i class="far fa-eye-slash"></i>
                                                <span className="input-border"></span>
                                            </div>
                                            {errors.password_confirm ? <div className='errorr'>{errors.password_confirm}</div> : false}
                                        </div>
                                        <div className="col-lg-6 col-md-6 m-auto mt-4">
                                            <div className="auth-btn">
                                                <button onClick={ChangePass} className='w-100'> Update Password</button>
                                            </div>
                                        </div>
                                        <div className='msg-box'></div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Update_Password