import { useParams } from 'react-router-dom'
import React, { useState, useEffect } from 'react'
import axios from 'axios';
import Moment from 'react-moment';
import { useProductContext } from '../context/productcontext';
import secureLocalStorage from 'react-secure-storage';

const Candidate_Profile = () => {

    const [data, setData] = useState(null);
    const [save, setSave] = useState(null);
    const { auth_data } = useProductContext();
    const { id } = useParams();
    useEffect(() => {
        GetWishlist();
        getCate();
    }, [id])

    const getCate = async () => {
        await axios.get(`https://works-wise.demo-customproject.com:4002/get-candidate-data/${id}`).then(res => {
            if (res.data.data.length !== 0) {
                setData(res.data.data[0]);
            }
        })
    }

    const GetWishlist = async () => {
        await axios.post(`https://works-wise.demo-customproject.com:4002/get-wishlist/${JSON.parse(secureLocalStorage.getItem('login_data')).id}/${id}`, null, { headers: { 'Auth': secureLocalStorage.getItem('accessToken') } }).then((res) => {
            setSave(res.data[0].status);
        });
    }

    const Wishlist = async (e) => {
        e.preventDefault();
        await axios.post(`https://works-wise.demo-customproject.com:4002/alter-wishlist/${auth_data?.id}/${id}`, null, { headers: { 'Auth': secureLocalStorage.getItem('accessToken') } }).then((res) => {
            setSave(res.data[0].status);
        });
    }

    
    console.log(auth_data)

    if (data === null) {
        return <div className='Loading'></div>
    }

    return (
        <section className="candidate-profile pt-10" >
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="candidate-prof-bsicinfo">
                            <div className='d-flexx'>
                                <div className="candi-top-info">
                                    <div className="candidate-prof-img">
                                        {
                                            data.profile_pic === null ? <div className='profile_namekey'>{data.first_name[0]}</div> :
                                                <img src={`https://works-wise.demo-customproject.com/admin/backend/public/uploads/users/${data.profile_pic}`} alt />
                                        }
                                        <div className="ring-container">
                                            {/* <div className="ringring" /> */}
                                            {/* <div className="online" /> */}
                                        </div>
                                    </div>
                                    <div className="candi-prof-info">
                                        <h3>{data.first_name} {data.last_name}</h3>
                                        <a href="#"><i className="fal fa-map-marker-alt" />{data.country}</a>
                                        <ul>
                                            <li>
                                                <div className="success-rate-icon">
                                                    <img src="images/success-rate.png" alt />
                                                </div>
                                                <span>99% Job Success</span>
                                            </li>
                                            <li>
                                                <div className="candi-verify-icon">
                                                    <i className="fas fa-star" />
                                                </div>
                                                <span>Top Rated</span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="candi-bscinfo-actbtn">
                                    {/* <a href="#" className="hire-candi">Hire</a> */}
                                    <a download={true} className="invite-candi" href={`https://works-wise.demo-customproject.com/admin/backend/public/uploads/users/${data.resume}`}>Download Resume</a>
                                    {auth_data.role == 2 && <a href="#" onClick={Wishlist} className={`save-candi ${save == 1 && 'saved' }`}><i className="fal fa-heart" /></a>}
                                </div>
                            </div>
                            <div className='hourly__rate'>
                                <h3>Hourly Rate</h3>
                                <span>{data.hourly_rate}<sub>{data.currency}</sub></span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-12">
                        <div className="candi-main-desc">
                            <div className="what-candi-provide">
                                <h2>{data.designation}</h2>
                                <p>{data.introduce}</p>
                                <h4>Skills</h4>
                                <ul className="candi-skills">
                                    {
                                        data.skills.split(',').map((itm) => {
                                            return <li>{itm}</li>
                                        })
                                    }
                                </ul>
                            </div>
                        </div>
                        <div className="candi-personal-det">
                            <div className="row">
                                <div className="col-lg-6">
                                    <div className="candi-work-info candi-pers-info candi-details3">
                                        <h3>Work</h3>
                                        <ul>
                                            {
                                                JSON.parse(data.work_history) === null || data.work_history === "[{}]" ? <div className='languages_no'>No Work History</div> : JSON.parse(data.work_history).map((item) => {
                                                    return <li>
                                                        <div className='d-flexxx'>
                                                            <span className="key">
                                                                <h5>{item.designation}</h5>
                                                                <h6>At : {item.company_name}</h6>
                                                            </span>
                                                            <span className="value">
                                                                <span className='date-badge'>
                                                                    <Moment format="MMM YYYY">{item.start_date}</Moment>
                                                                    <span>-</span>
                                                                    <Moment format="MMM YYYY">{item.end_date}</Moment>
                                                                </span>
                                                            </span>
                                                        </div>
                                                        <span className='decs_box'>{item.description}</span>
                                                    </li>
                                                })
                                            }

                                        </ul>
                                    </div>
                                    <div className="candi-pers-info candi-details1">

                                        <h3>Education</h3>
                                        <ul>
                                            {
                                                JSON.parse(data.education) === null || data.education === "[{}]" ? <div className='notfound'>No Education Found</div> : JSON.parse(data.education).map((item) => {
                                                    return <li>
                                                        <span className="key">
                                                            <h5>{item.degree}</h5>
                                                            <h6>From : {item.institute}</h6>
                                                        </span>
                                                        <span className="value">
                                                            <span className='date-badge'>
                                                                <Moment format="MMM YYYY">{item.start_date}</Moment>
                                                                <span>-</span>
                                                                <Moment format="MMM YYYY">{item.end_date}</Moment>
                                                            </span>
                                                        </span>
                                                    </li>
                                                })
                                            }

                                        </ul>

                                    </div>
                                    <div className="candi-edu-info candi-pers-info candi-details2" style={{ borderLeft: "1px solid rgb(199, 199, 199)" }}>
                                        <h3>Certification</h3>
                                        <ul>
                                            {
                                                JSON.parse(data.certification) === null || data.certification === "[{}]" ? <div className='notfound'>No Certification Found</div> : JSON.parse(data.certification).map((item) => {
                                                    return <li>
                                                        <span className="key" style={{ width: "90%" }}>
                                                            <h5>{item.institute}</h5>
                                                            <h6>{item.title}</h6>
                                                        </span>
                                                    </li>
                                                })
                                            }

                                        </ul>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="candi-edu-info candi-pers-info candi-details2">
                                        <h3>Languages</h3>
                                        <ul>
                                            <li>
                                                <span className="value languages_box_wrpp lang-flexxs">
                                                    {
                                                        JSON.parse(data.language) === null ? <div className='languages_no'>Languages Not Selected</div> : JSON.parse(data.language).map((itm) => {
                                                            return <div>
                                                                <h5>{itm.lang}</h5>
                                                                <p>{itm.lavel}</p>
                                                            </div>
                                                        })
                                                    }
                                                </span>
                                            </li>

                                        </ul>
                                    </div>
                                    <div className="candi-edu-info candi-pers-info candi-details2">
                                        <h3>Personal Information</h3>
                                        <ul>
                                            <li>
                                                <span className="key">My Citizenship</span>
                                                <span className="value">{data.country}</span>
                                            </li>
                                            <li>
                                                <span className="key">Email</span>
                                                <span className="value">{data.email}</span>
                                            </li>
                                            <li>
                                                <span className="key">Gender</span>
                                                <span className="value">{data.gender}</span>
                                            </li>
                                            <li>
                                                <span className="key">Age</span>
                                                <span className="value">{data.age}</span>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className='candi-edu-info candi-pers-info candi-details2 award_box_wrpp'>
                                        <h3>Awards</h3>
                                        <ul>
                                            {
                                                JSON.parse(data.awards) === null || data.awards === "[{}]" ? <div className='notfound'>No Awards Found</div>
                                                    : JSON.parse(data.awards).map((item) => {
                                                        return <li>
                                                            <span className="value">{item.institute}</span>
                                                            <span className="value">{item.title}</span>
                                                        </li>
                                                    })
                                            }

                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section >

    )
}

export default Candidate_Profile