import React, { useState } from 'react'
import axios from 'axios';
import $ from 'jquery';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { omit } from 'lodash'
import secureLocalStorage from 'react-secure-storage';
import PhoneInput from 'react-phone-number-input'
import 'react-phone-number-input/style.css'
import { isValidPhoneNumber } from 'react-phone-number-input'
const renderHTML = (escapedHTML) => React.createElement("div", { dangerouslySetInnerHTML: { __html: escapedHTML } });



function Sign_up() {

    const navigate = useNavigate();
    useEffect(() => {
        if (secureLocalStorage.getItem('login_data') != null) {
            navigate('/')
        }
    })
    const [user, setUser] = useState({
        companyname: "",
        companysize: "",
        confirm_pass: "",
        country: "",
        email: "",
        first_name: "",
        last_name: "",
        password: "",
        phone: "",
        role: "",
        terms: "",
    });
    const [errors, setErrors] = useState({});
    const setVal = (e) => {
        e.persist();
        setUser((prev) => ({ ...prev, [e.target.name]: e.target.value }));
        validate(e.target.name, e.target.value);
    }
    const setPhone = (e) => {
        console.log(e)
        setUser((prev) => ({ ...prev, ['phone']: e }));
        validate('phone', e);
    }

    const validate = (name, value) => {
        switch (name) {
            case 'first_name':
                if (value == undefined || value == '') {
                    setErrors({
                        ...errors,
                        first_name: 'Field cannot be empty'
                    })
                } else if (value.length <= 2) {
                    setErrors({
                        ...errors,
                        first_name: 'First name atleast have 3 letters'
                    })
                } else {
                    let newObj = omit(errors, "first_name");
                    setErrors(newObj);
                }
                break;
            case 'last_name':
                if (value == undefined || value == '') {
                    setErrors({
                        ...errors,
                        last_name: 'Field cannot be empty'
                    })
                } else if (value.length <= 2) {
                    setErrors({
                        ...errors,
                        last_name: 'Last name atleast have 3 letters'
                    })
                } else {
                    let newObj = omit(errors, "last_name");
                    setErrors(newObj);
                }
                break;
            case 'country':
                if (value == undefined || value == '') {
                    setErrors({
                        ...errors,
                        country: 'Field cannot be empty'
                    })
                } else {
                    let newObj = omit(errors, "country");
                    setErrors(newObj);
                }
                break;
            case 'role':
                if (value == undefined || value == '') {
                    setErrors({
                        ...errors,
                        role: 'Field cannot be empty'
                    })
                } else {
                    let newObj = omit(errors, "role");
                    setErrors(newObj);
                }
                break;
            case 'companyname':
                if (value == undefined || value == '' && user.role == '2') {
                    setErrors({
                        ...errors,
                        companyname: 'Field cannot be empty'
                    })
                } else {
                    let newObj = omit(errors, "companyname");
                    setErrors(newObj);
                }
                break;
            case 'companysize':
                if (value == undefined || value == '' && user.role == '2') {
                    setErrors({
                        ...errors,
                        companysize: 'Field cannot be empty'
                    })
                } else {
                    let newObj = omit(errors, "companysize");
                    setErrors(newObj);
                }
                break;
            case 'designation':
                if (value == undefined || value == '' && user.role == '2') {
                    setErrors({
                        ...errors,
                        designation: 'Field cannot be empty'
                    })
                } else {
                    let newObj = omit(errors, "designation");
                    setErrors(newObj);
                }
                break;
            case 'terms':
                if (value == undefined || value == '' || value == false) {
                    setErrors({
                        ...errors,
                        terms: 'Please accept the Work Wise Terms & Conditions'
                    })
                } else {
                    let newObj = omit(errors, "terms");
                    setErrors(newObj);
                }
                break;
            case 'phone':
                if (value === null || value == undefined || value == '') {
                    setErrors({ ...errors, phone: 'Field cannot be empty' })
                }
                else if (!isValidPhoneNumber(value)) {
                    setErrors({ ...errors, phone: 'Invalid Phone Number' })
                } else {
                    let newObj = omit(errors, "phone");
                    setErrors(newObj);
                }
                break;
            case 'email':
                if (user.role == 0) {
                    if (
                        !new RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/).test(value)
                    ) {
                        setErrors({
                            ...errors,
                            email: 'Enter a valid email address'
                        })
                    } else {
                        let newObj = omit(errors, "email");
                        setErrors(newObj);
                    }
                } else {
                    if (
                        !new RegExp(/^[a-zA-Z0-9._%+-]+@(?!gmail.com)(?!yahoo.com)(?!hotmail.com)(?!yahoo.co.in)(?!aol.com)(?!live.com)(?!outlook.com)[a-zA-Z0-9_-]+.[a-zA-Z0-9-.]{2,61}$/).test(value)
                    ) {
                        setErrors({
                            ...errors,
                            email: 'Enter a valid business email address'
                        })
                    } else {
                        let newObj = omit(errors, "email");
                        setErrors(newObj);
                    }
                }

                break;

            case 'password':
                if (
                    !new RegExp(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$/).test(value)
                ) {
                    setErrors({
                        ...errors,
                        password: 'Password should contains atleast 8 charaters <br/> and containing uppercase,lowercase <br/> and numbers'
                    })
                } else {

                    let newObj = omit(errors, "password");
                    setErrors(newObj);

                }
                break;
            case 'confirm_pass':
                if (value !== user.password) {
                    setErrors({
                        ...errors,
                        confirm_pass: "Password does not match"
                    })
                } else {
                    let newObj = omit(errors, "confirm_pass");
                    setErrors(newObj);
                }
                break;

            default:
                break;
        }
    }
    let err = {};
    const validate2 = (name, value) => {
        if (name == 'first_name') {
            if (value == undefined || value == '') {
                err = {
                    ...err,
                    first_name: 'Field cannot be empty'
                }
            } else if (value.length <= 2) {
                err = {
                    ...err,
                    first_name: 'First name atleast have 3 letters'
                }
            } else {
                let newObj = omit(err, "first_name");
                err = newObj;
            }
        }
        if (name == 'last_name') {
            if (value == undefined || value == '') {
                err = {
                    ...err,
                    last_name: 'Field cannot be empty'
                }
            } else if (value.length <= 2) {
                err = {
                    ...err,
                    last_name: 'Last name atleast have 3 letters'
                }
            } else {
                let newObj = omit(err, "last_name");
                err = newObj;
            }
        }
        if (name == 'country') {
            if (value == undefined || value == '') {
                err = {
                    ...err,
                    country: 'Field cannot be empty'
                }
            } else {
                let newObj = omit(err, "country");
                err = newObj;
            }
        }
        if (name == 'role') {
            if (value == undefined || value == '') {
                err = {
                    ...err,
                    role: 'Select You want to be'
                }
            } else {
                let newObj = omit(err, "role");
                err = newObj;
            }
        }
        if (name == 'companyname' && user.role == '2') {
            if (value == undefined || value == '') {
                err = {
                    ...err,
                    companyname: 'Field cannot be empty'
                }
            } else {
                let newObj = omit(err, "companyname");
                err = newObj;
            }
        }
        if (name == 'companysize' && user.role == '2') {
            if (value == undefined || value == '') {
                err = {
                    ...err,
                    companysize: 'Field cannot be empty'
                }
            } else {
                let newObj = omit(err, "companysize");
                err = newObj;
            }
        }
        if (name == 'designation' && user.role == '2') {
            if (value == undefined || value == '') {
                err = {
                    ...err,
                    designation: 'Field cannot be empty'
                }
            } else {
                let newObj = omit(err, "designation");
                err = newObj;
            }
        }
        if (name == 'terms') {
            if (value == undefined || value == '' || value == false) {
                err = {
                    ...err,
                    terms: 'Please accept the Work Wise Terms & Conditions'
                }
            } else {
                let newObj = omit(err, "terms");
                err = newObj;
            }
        }
        if (name == 'phone') {
            if (value === null || value == undefined || value == '') {
                console.log('asdasd')
                err = { ...err, phone: 'Field cannot be empty' }
            }
            else if (!isValidPhoneNumber(value)) {
                err = { ...err, phone: 'Invalid Phone Number' }
            } else {
                let newObj = omit(err, "phone");
                err = newObj;
            }
        }
        if (name == 'email') {
            if (user.role == 0) {
                if (
                    !new RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/).test(value)
                ) {
                    err = {
                        ...err,
                        email: 'Enter a valid email address'
                    }
                } else {
                    let newObj = omit(err, "email");
                    err = newObj;
                }
            } else {
                if (
                    !new RegExp(/^[a-zA-Z0-9._%+-]+@(?!gmail.com)(?!yahoo.com)(?!hotmail.com)(?!yahoo.co.in)(?!aol.com)(?!live.com)(?!outlook.com)[a-zA-Z0-9_-]+.[a-zA-Z0-9-.]{2,61}$/).test(value)
                ) {
                    err = {
                        ...err,
                        email: 'Enter a valid business email address'
                    }
                } else {
                    let newObj = omit(err, "email");
                    err = newObj;
                }
            }
        }
        if (name == 'password') {
            if (
                !new RegExp(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$/).test(value)
            ) {
                err = {
                    ...err,
                    password: 'Password should contains atleast 8 charaters <br/> and containing uppercase,lowercase <br/> and numbers'
                }
            } else {

                let newObj = omit(err, "password");
                err = newObj;

            }
        }
        if (name == 'confirm_pass') {
            if (value !== user.password) {
                err = {
                    ...err,
                    confirm_pass: "Password does not match"
                }
            } else {
                let newObj = omit(err, "confirm_pass");
                err = newObj;
            }
        }
        setErrors(err);
    }

    const SendOtp = async (email) => {
        if (email != '') {
            await axios.post('https://works-wise.demo-customproject.com:4002/forgot-pass', { email: email }).then(function (response) {
                if (response.data.status == '200') {
                    secureLocalStorage.setItem('forgot_email', JSON.stringify({ email: email }));
                    navigate('/otp-verify');
                } else {
                    $('.msg-box').removeClass('green')
                    $('.msg-box').addClass('red').text(response.data.response);
                }
            }).catch(function (error) { });
        }
    }
    const setCheck = (e) => {
        console.log(e)
        setUser((prev) => ({ ...prev, [e.target.name]: e.target.checked }));
        validate(e.target.name, e.target.checked);
    }
    const addUser = async (e) => {
        e.preventDefault();
        $('.msg-box').removeClass('green')
        $('.msg-box').removeClass('red')
        Object.keys(user).map((item) => {
            validate2(item, user[item]);
        })
        if (Object.keys(errors).length === 0 && Object.keys(err).length === 0) {
            if (user.first_name && user.last_name && user.email && user.password) {
                e.target.classList.add('loadin');
                console.log(user)
                await axios.post('https://works-wise.demo-customproject.com:4002/addUser', { info: user }).then(function (response) {
                    if (response.data.status == '200') {
                        e.target.classList.remove('loadin');
                        $('.msg-box').removeClass('red')
                        $('.msg-box').addClass('green').text(response.data.message);
                        SendOtp(user.email)
                    } else {
                        e.target.classList.remove('loadin');
                        $('.msg-box').removeClass('green')
                        $('.msg-box').addClass('red').text(response.data.message);
                    }
                }).catch(function (error) {
                    console.log(error);
                });
            } else {
                e.target.classList.remove('loadin');
                $('.msg-box').removeClass('green')
                $('.msg-box').addClass('red').text("Some Field are Empty");
            }
        }

    }

    useEffect(() => {
        $(".pasWord ~ i").unbind().click(function () {
            $(this).toggleClass("fa-eye fa-eye-slash");
            let inPut = $(this).parent().find('.pasWord');
            if (inPut.attr('type') === 'password') {
                inPut.attr("type", "text");
            } else {
                inPut.attr("type", "password");
            }
        })
    })

    return (
        <>
            <section className="Sign__up">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-8">
                            <div className="signupp_box">
                                <form>
                                    {
                                        user.role === "" ? <h2>Join as a candidate or employer</h2> : (user.role === "2" ? <h2><i class="fa-duotone fa-briefcase"></i> Join as a employer</h2> : <h2><i class="fa-solid fa-user-tie-hair"></i> Join as a candidate</h2>)
                                    }
                                    {user.role === "" ? <div className='row'>
                                        <div className="col-lg-6 col-md-6 col-sm-6">
                                            <div className="wrapper">
                                                <div className="card">
                                                    <input className="input" type="radio" name="role" onClick={setVal} defaultValue="0" />
                                                    <span className="check" />
                                                    <label className="label">
                                                        <i class="fa-solid fa-user-tie-hair"></i>
                                                        <h3>Candidate</h3>
                                                        <p>Looking for Jobs</p>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-6 col-sm-6">
                                            <div className="wrapper">
                                                <div className="card">
                                                    <input className="input" type="radio" name="role" onClick={setVal} defaultValue="2" />
                                                    <span className="check" />
                                                    <label className="label">
                                                        <i class="fa-duotone fa-briefcase"></i>
                                                        <h3>Employer</h3>
                                                        <p>Hiring Candidates</p>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                        {errors.role ? <div className='errorr'>{errors.role}</div> : false}
                                    </div>
                                        : (user.role === '0' ? <div className="row">
                                            <div className="col-lg-6 col-md-6 col-sm-6">
                                                <div className="form">
                                                    <input className="input" placeholder="First Name" type="text" onChange={setVal} name="first_name" />
                                                    <span className="input-border"></span>
                                                    {/* {errors.first_name ? <div className='errorr'>{errors.first_name}</div> : false} */}
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-6 col-sm-6">
                                                <div className="form">
                                                    <input className="input" placeholder="Last Name" onChange={setVal} type="text" name="last_name" />
                                                    <span className="input-border"></span>
                                                    {/* {errors.last_name ? <div className='errorr'>{errors.last_name}</div> : false} */}
                                                </div>
                                            </div>

                                            <div className="col-lg-6 col-md-6 col-sm-6">
                                                <div className="form">
                                                    <PhoneInput className="input" defaultCountry="US" international="false" placeholder="Enter Your Phone Number" onChange={setPhone} name="phone" />
                                                    <span className="input-border"></span>
                                                    {/* {errors.phone ? <div className='errorr'>{errors.phone}</div> : false} */}
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-6 col-sm-6">
                                                <div className="form">
                                                    <select className="input with-border" onChange={setVal} name='country'>
                                                        <option value="" selected hidden disabled>Your Location</option>
                                                        <option value="Argentina">Argentina</option>
                                                        <option value="Armenia">Armenia</option>
                                                        <option value="Aruba">Aruba</option>
                                                        <option value="Australia">Australia</option>
                                                        <option value="Austria">Austria</option>
                                                        <option value="Azerbaijan">Azerbaijan</option>
                                                        <option value="Bahamas">Bahamas</option>
                                                        <option value="Bahrain">Bahrain</option>
                                                        <option value="Bangladesh">Bangladesh</option>
                                                        <option value="Barbados">Barbados</option>
                                                        <option value="Belarus">Belarus</option>
                                                        <option value="Belgium">Belgium</option>
                                                        <option value="Belize">Belize</option>
                                                        <option value="Benin">Benin</option>
                                                        <option value="Bermuda">Bermuda</option>
                                                        <option value="Bhutan">Bhutan</option>
                                                        <option value="Bulgaria">Bulgaria</option>
                                                        <option value="Burkina Faso">Burkina Faso</option>
                                                        <option value="Burundi">Burundi</option>
                                                        <option value="Cambodia">Cambodia</option>
                                                        <option value="Cameroon">Cameroon</option>
                                                        <option value="Canada">Canada</option>
                                                        <option value="Cape Verde">Cape Verde</option>
                                                        <option value="Cayman Islands">Cayman Islands</option>
                                                        <option value="Colombia">Colombia</option>
                                                        <option value="Comoros">Comoros</option>
                                                        <option value="Congo">Congo</option>
                                                        <option value="Cook Islands">Cook Islands</option>
                                                        <option value="Costa Rica">Costa Rica</option>
                                                        <option value="Côte d'Ivoire">Côte d'Ivoire</option>
                                                        <option value="Croatia">Croatia</option>
                                                        <option value="Cuba">Cuba</option>
                                                        <option value="Curaçao">Curaçao</option>
                                                        <option value="Cyprus">Cyprus</option>
                                                        <option value="Czech Republic">Czech Republic</option>
                                                        <option value="Denmark">Denmark</option>
                                                        <option value="Djibouti">Djibouti</option>
                                                        <option value="Dominica">Dominica</option>
                                                        <option value="Dominican Republic">Dominican Republic</option>
                                                        <option value="Ecuador">Ecuador</option>
                                                        <option value="Egypt">Egypt</option>
                                                        <option value="Guadeloupe">Guadeloupe</option>
                                                        <option value="Guam">Guam</option>
                                                        <option value="Guatemala">Guatemala</option>
                                                        <option value="Guernsey">Guernsey</option>
                                                        <option value="Guinea">Guinea</option>
                                                        <option value="Guinea-Bissau">Guinea-Bissau</option>
                                                        <option value="Guyana">Guyana</option>
                                                        <option value="Haiti">Haiti</option>
                                                        <option value="Honduras">Honduras</option>
                                                        <option value="Hong Kong">Hong Kong</option>
                                                        <option value="Hungary">Hungary</option>
                                                        <option value="Iceland">Iceland</option>
                                                        <option value="India">India</option>
                                                        <option value="Indonesia">Indonesia</option>
                                                        <option value="Norway">Norway</option>
                                                        <option value="Oman">Oman</option>
                                                        <option value="Pakistan">Pakistan</option>
                                                        <option value="Palau">Palau</option>
                                                        <option value="Panama">Panama</option>
                                                        <option value="Papua">Papua New Guinea</option>
                                                        <option value="Paraguay">Paraguay</option>
                                                        <option value="Peru">Peru</option>
                                                        <option value="Philippines">Philippines</option>
                                                        <option value="Pitcairn">Pitcairn</option>
                                                        <option value="Poland">Poland</option>
                                                        <option value="Portugal">Portugal</option>
                                                        <option value="Puerto Rico">Puerto Rico</option>
                                                        <option value="Qatar">Qatar</option>
                                                        <option value="Réunion">Réunion</option>
                                                        <option value="Romania">Romania</option>
                                                        <option value="Russian Federation">Russian Federation</option>
                                                        <option value="Rwanda">Rwanda</option>
                                                        <option value="Swaziland">Swaziland</option>
                                                        <option value="Sweden">Sweden</option>
                                                        <option value="Switzerland">Switzerland</option>
                                                        <option value="Turkey">Turkey</option>
                                                        <option value="Turkmenistan">Turkmenistan</option>
                                                        <option value="Tuvalu">Tuvalu</option>
                                                        <option value="Uganda">Uganda</option>
                                                        <option value="Ukraine">Ukraine</option>
                                                        <option value="United Kingdom">United Kingdom</option>
                                                        <option value="United States">United States</option>
                                                        <option value="Uruguay">Uruguay</option>
                                                        <option value="Uzbekistan">Uzbekistan</option>
                                                        <option value="Yemen">Yemen</option>
                                                        <option value="Zambia">Zambia</option>
                                                        <option value="Zimbabwe">Zimbabwe</option>
                                                    </select>
                                                    <span className="input-border"></span>
                                                    {/* {errors.country ? <div className='errorr'>{errors.country}</div> : false} */}
                                                </div>
                                            </div>
                                            <div className="col-lg-12 col-md-12 col-sm-12">
                                                <div className="form">
                                                    <input className="input" placeholder="Enter Your Email" type='email' onChange={setVal} name="email" />
                                                    <span className="input-border"></span>
                                                    {/* {errors.email ? <div className='errorr'>{errors.email}</div> : false} */}
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-6 col-sm-6">
                                                <div className="form">
                                                    <input className="input pasWord" type='password' placeholder="Password" onChange={setVal} name="password" />
                                                    <span className="input-border"></span>
                                                    <i class="far fa-eye-slash"></i>
                                                    {/* {errors.password ? <div className='errorr'>{errors.password}</div> : false} */}
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-6 col-sm-6">
                                                <div className="form">
                                                    <input className="input pasWord" type='password' placeholder="Confirm Password" onChange={setVal} name="confirm_pass" />
                                                    <span className="input-border"></span>
                                                    <i class="far fa-eye-slash"></i>
                                                    {/* {errors.confirm_pass ? <div className='errorr'>{errors.confirm_pass}</div> : false} */}
                                                </div>
                                            </div>
                                            <div className="col-lg-12 col-md-12 col-sm-12 d-flex justify-content-between">
                                                <label className='check_box'><input type='checkbox' name='terms' onChange={setCheck} /> Yes, I understand and agree to the Work Wise <br /> Terms & Conditions</label>
                                                <button type='submit' disable={Object.keys(errors).length === 0 ? 'true' : 'false'} onClick={addUser}>Create my account</button>
                                            </div>
                                            {/* {errors.terms ? <div className='errorr'>{errors.terms}</div> : false} */}
                                            <div className='err-wrpper'>
                                                {
                                                    Object.keys(errors).length > 0 ? Object.keys(errors).map((err) => {
                                                        return <div className='errorr'>{renderHTML(errors[err])}</div>
                                                    }) : false
                                                }
                                            </div>
                                            <div className='msg-box'></div>
                                        </div> : <div className="row">
                                            <div className="col-lg-6 col-md-6 col-sm-6">
                                                <div className="form">
                                                    <input className="input" placeholder="First Name" type="text" onChange={setVal} name="first_name" />
                                                    <span className="input-border"></span>
                                                    {/* {errors.first_name ? <div className='errorr'>{errors.first_name}</div> : false} */}
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-6 col-sm-6">
                                                <div className="form">
                                                    <input className="input" placeholder="Last Name" onChange={setVal} type="text" name="last_name" />
                                                    <span className="input-border"></span>
                                                    {/* {errors.last_name ? <div className='errorr'>{errors.last_name}</div> : false} */}
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-6 col-sm-6">
                                                <div className="form">
                                                    <input className="input" placeholder="Company Name" onChange={setVal} name="companyname" />
                                                    <span className="input-border"></span>
                                                    {/* {errors.companyname ? <div className='errorr'>{errors.companyname}</div> : false} */}
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-6 col-sm-6">
                                                <div className="form">
                                                    <select className="input" name="companysize" onChange={setVal}>
                                                        <option hidden disabled selected>Size Of Company</option>
                                                        <option value="10">10</option>
                                                        <option value="10-50">10-50</option>
                                                        <option value="50-500">50-500</option>
                                                        <option value="500+">500+</option>
                                                    </select>
                                                    <span className="input-border"></span>
                                                    {/* {errors.companysize ? <div className='errorr'>{errors.companysize}</div> : false} */}
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-6 col-sm-6">
                                                <div className="form">
                                                    <PhoneInput className="input" defaultCountry="US" international="false" placeholder="Enter Your Phone Number" onChange={setPhone} name="phone" />
                                                    <span className="input-border"></span>
                                                    {/* {errors.phone ? <div className='errorr'>{errors.phone}</div> : false} */}
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-6 col-sm-6">
                                                <div className="form">
                                                    <select className="input with-border" onChange={setVal} name='country'>
                                                        <option value="" selected hidden disabled>Your Location</option>
                                                        <option value="Argentina">Argentina</option>
                                                        <option value="Armenia">Armenia</option>
                                                        <option value="Aruba">Aruba</option>
                                                        <option value="Australia">Australia</option>
                                                        <option value="Austria">Austria</option>
                                                        <option value="Azerbaijan">Azerbaijan</option>
                                                        <option value="Bahamas">Bahamas</option>
                                                        <option value="Bahrain">Bahrain</option>
                                                        <option value="Bangladesh">Bangladesh</option>
                                                        <option value="Barbados">Barbados</option>
                                                        <option value="Belarus">Belarus</option>
                                                        <option value="Belgium">Belgium</option>
                                                        <option value="Belize">Belize</option>
                                                        <option value="Benin">Benin</option>
                                                        <option value="Bermuda">Bermuda</option>
                                                        <option value="Bhutan">Bhutan</option>
                                                        <option value="Bulgaria">Bulgaria</option>
                                                        <option value="Burkina Faso">Burkina Faso</option>
                                                        <option value="Burundi">Burundi</option>
                                                        <option value="Cambodia">Cambodia</option>
                                                        <option value="Cameroon">Cameroon</option>
                                                        <option value="Canada">Canada</option>
                                                        <option value="Cape Verde">Cape Verde</option>
                                                        <option value="Cayman Islands">Cayman Islands</option>
                                                        <option value="Colombia">Colombia</option>
                                                        <option value="Comoros">Comoros</option>
                                                        <option value="Congo">Congo</option>
                                                        <option value="Cook Islands">Cook Islands</option>
                                                        <option value="Costa Rica">Costa Rica</option>
                                                        <option value="Côte d'Ivoire">Côte d'Ivoire</option>
                                                        <option value="Croatia">Croatia</option>
                                                        <option value="Cuba">Cuba</option>
                                                        <option value="Curaçao">Curaçao</option>
                                                        <option value="Cyprus">Cyprus</option>
                                                        <option value="Czech Republic">Czech Republic</option>
                                                        <option value="Denmark">Denmark</option>
                                                        <option value="Djibouti">Djibouti</option>
                                                        <option value="Dominica">Dominica</option>
                                                        <option value="Dominican Republic">Dominican Republic</option>
                                                        <option value="Ecuador">Ecuador</option>
                                                        <option value="Egypt">Egypt</option>
                                                        <option value="Guadeloupe">Guadeloupe</option>
                                                        <option value="Guam">Guam</option>
                                                        <option value="Guatemala">Guatemala</option>
                                                        <option value="Guernsey">Guernsey</option>
                                                        <option value="Guinea">Guinea</option>
                                                        <option value="Guinea-Bissau">Guinea-Bissau</option>
                                                        <option value="Guyana">Guyana</option>
                                                        <option value="Haiti">Haiti</option>
                                                        <option value="Honduras">Honduras</option>
                                                        <option value="Hong Kong">Hong Kong</option>
                                                        <option value="Hungary">Hungary</option>
                                                        <option value="Iceland">Iceland</option>
                                                        <option value="India">India</option>
                                                        <option value="Indonesia">Indonesia</option>
                                                        <option value="Norway">Norway</option>
                                                        <option value="Oman">Oman</option>
                                                        <option value="Pakistan">Pakistan</option>
                                                        <option value="Palau">Palau</option>
                                                        <option value="Panama">Panama</option>
                                                        <option value="Papua">Papua New Guinea</option>
                                                        <option value="Paraguay">Paraguay</option>
                                                        <option value="Peru">Peru</option>
                                                        <option value="Philippines">Philippines</option>
                                                        <option value="Pitcairn">Pitcairn</option>
                                                        <option value="Poland">Poland</option>
                                                        <option value="Portugal">Portugal</option>
                                                        <option value="Puerto Rico">Puerto Rico</option>
                                                        <option value="Qatar">Qatar</option>
                                                        <option value="Réunion">Réunion</option>
                                                        <option value="Romania">Romania</option>
                                                        <option value="Russian Federation">Russian Federation</option>
                                                        <option value="Rwanda">Rwanda</option>
                                                        <option value="Swaziland">Swaziland</option>
                                                        <option value="Sweden">Sweden</option>
                                                        <option value="Switzerland">Switzerland</option>
                                                        <option value="Turkey">Turkey</option>
                                                        <option value="Turkmenistan">Turkmenistan</option>
                                                        <option value="Tuvalu">Tuvalu</option>
                                                        <option value="Uganda">Uganda</option>
                                                        <option value="Ukraine">Ukraine</option>
                                                        <option value="United Kingdom">United Kingdom</option>
                                                        <option value="United States">United States</option>
                                                        <option value="Uruguay">Uruguay</option>
                                                        <option value="Uzbekistan">Uzbekistan</option>
                                                        <option value="Yemen">Yemen</option>
                                                        <option value="Zambia">Zambia</option>
                                                        <option value="Zimbabwe">Zimbabwe</option>
                                                    </select>
                                                    <span className="input-border"></span>
                                                    {/* {errors.country ? <div className='errorr'>{errors.country}</div> : false} */}
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-6 col-sm-6">
                                                <div className="form">
                                                    <input className="input" placeholder="Enter Your Email" type='email' onChange={setVal} name="email" />
                                                    <span className="input-border"></span>
                                                    {/* {errors.email ? <div className='errorr'>{errors.email}</div> : false} */}
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-6 col-sm-6">
                                                <div className="form">
                                                    <input className="input" placeholder="Your Designation" onChange={setVal} name="designation" />
                                                    <span className="input-border"></span>
                                                    {/* {errors.designation ? <div className='errorr'>{errors.designation}</div> : false} */}
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-6 col-sm-6">
                                                <div className="form">
                                                    <input className="input pasWord" type='password' placeholder="Password" onChange={setVal} name="password" />
                                                    <span className="input-border"></span>
                                                    <i class="far fa-eye-slash"></i>
                                                    {/* {errors.password ? <div className='errorr'>{errors.password}</div> : false} */}
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-6 col-sm-6">
                                                <div className="form">
                                                    <input className="input pasWord" type='password' placeholder="Confirm Password" onChange={setVal} name="confirm_pass" />
                                                    <span className="input-border"></span>
                                                    <i class="far fa-eye-slash"></i>
                                                    {/* {errors.confirm_pass ? <div className='errorr'>{errors.confirm_pass}</div> : false} */}
                                                </div>
                                            </div>

                                            <div className="col-lg-12 col-md-12 col-sm-12 d-flex justify-content-between">
                                                <label className='check_box'><input type='checkbox' name='terms' onChange={setCheck} /> Yes, I understand and agree to the Work Wise <br /> Terms & Conditions</label>
                                                <button type='submit' disable={Object.keys(errors).length === 0 ? 'true' : 'false'} onClick={addUser}>Create my account</button>
                                            </div>
                                            {/* {errors.terms ? <div className='errorr'>{errors.terms}</div> : false} */}
                                            <div className='msg-box'></div>
                                            <div className='err-wrpper'>
                                                {
                                                    Object.keys(errors).length > 0 ? Object.keys(errors).map((err) => {
                                                        return <div className='errorr'>{renderHTML(errors[err])}</div>
                                                    }) : false
                                                }
                                            </div>
                                        </div>)}
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>

    )
}

export default Sign_up
