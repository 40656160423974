import React, { useState } from 'react'
import Candidate_box from '../components/Candidate_box'
import { useProductContext } from '../context/productcontext'
import $ from 'jquery'

const Candidate_Listing = () => {
    const { client, getCandidates, isloading } = useProductContext();
    const [filter, setFilter] = useState({
        keywords: '',
        location: '',
        salary_min: 0,
        salary_max: 300000000,
    });
    const GetFilter = (e) => {
        if (e.target.name === 'salary') {
            let min = e.target.value.split('-')[0];
            let max = e.target.value.split('-')[1];
            setFilter((prev) => ({ ...prev, ['salary_min']: parseInt(min) }));
            setFilter((prev) => ({ ...prev, ['salary_max']: parseInt(max) }));
        } else {
            setFilter((prev) => ({ ...prev, [e.target.name]: e.target.value }));
        }
    }
    const changeLoc = async (e) => {
        e.preventDefault();
        let val = $('[name="location"]').val();
        setFilter((prev) => ({ ...prev, ['location']: val }));
        console.log(val)
        if (val !== '') {
            await getCandidates(val);
        } return false
    }
    const { candidates } = useProductContext();
    if (candidates === null || client === null) {
        return <div className='Loading'></div>
    }
    return (
        <>
            <section className="search-job">
                <div className="container">
                    <div className="row search-job-bg">
                        <div className="col-lg-12">
                            <form>
                                <div className="search-heading">
                                    <h3>Search Candidate</h3>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        <div className="search-job-input">
                                            <input type="text" name="keywords" onChange={GetFilter} placeholder="Search..." />
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="search-job-inputss">
                                            <select class="with-border" data-size="7" onChange={changeLoc} title="Select Job Type" data-live-search="true" name="location">
                                                <option value="United States" hidden="" disabled="">United States</option>
                                                <option value="Argentina">Argentina</option>
                                                <option value="Armenia">Armenia</option>
                                                <option value="Aruba">Aruba</option>
                                                <option value="Australia">Australia</option>
                                                <option value="Austria">Austria</option>
                                                <option value="Azerbaijan">Azerbaijan</option>
                                                <option value="Bahamas">Bahamas</option>
                                                <option value="Bahrain">Bahrain</option>
                                                <option value="Bangladesh">Bangladesh</option>
                                                <option value="Barbados">Barbados</option>
                                                <option value="Belarus">Belarus</option>
                                                <option value="Belgium">Belgium</option>
                                                <option value="Belize">Belize</option>
                                                <option value="Benin">Benin</option>
                                                <option value="Bermuda">Bermuda</option>
                                                <option value="Bhutan">Bhutan</option>
                                                <option value="Bulgaria">Bulgaria</option>
                                                <option value="Burkina Faso">Burkina Faso</option>
                                                <option value="Burundi">Burundi</option>
                                                <option value="Cambodia">Cambodia</option>
                                                <option value="Cameroon">Cameroon</option>
                                                <option value="Canada">Canada</option>
                                                <option value="Cape Verde">Cape Verde</option>
                                                <option value="Cayman Islands">Cayman Islands</option>
                                                <option value="Colombia">Colombia</option>
                                                <option value="Comoros">Comoros</option>
                                                <option value="Congo">Congo</option>
                                                <option value="Cook Islands">Cook Islands</option>
                                                <option value="Costa Rica">Costa Rica</option>
                                                <option value="Côte d'Ivoire">Côte d'Ivoire</option>
                                                <option value="Croatia">Croatia</option>
                                                <option value="Cuba">Cuba</option>
                                                <option value="Curaçao">Curaçao</option>
                                                <option value="Cyprus">Cyprus</option>
                                                <option value="Czech Republic">Czech Republic</option>
                                                <option value="Denmark">Denmark</option>
                                                <option value="Djibouti">Djibouti</option>
                                                <option value="Dominica">Dominica</option>
                                                <option value="Dominican Republic">Dominican Republic</option>
                                                <option value="Ecuador">Ecuador</option>
                                                <option value="Egypt">Egypt</option>
                                                <option value="Guadeloupe">Guadeloupe</option>
                                                <option value="Guam">Guam</option>
                                                <option value="Guatemala">Guatemala</option>
                                                <option value="Guernsey">Guernsey</option>
                                                <option value="Guinea">Guinea</option>
                                                <option value="Guinea-Bissau">Guinea-Bissau</option>
                                                <option value="Guyana">Guyana</option>
                                                <option value="Haiti">Haiti</option>
                                                <option value="Honduras">Honduras</option>
                                                <option value="Hong Kong">Hong Kong</option>
                                                <option value="Hungary">Hungary</option>
                                                <option value="Iceland">Iceland</option>
                                                <option value="India">India</option>
                                                <option value="Indonesia">Indonesia</option>
                                                <option value="Norway">Norway</option>
                                                <option value="Oman">Oman</option>
                                                <option value="Pakistan">Pakistan</option>
                                                <option value="Palau">Palau</option>
                                                <option value="Panama">Panama</option>
                                                <option value="Papua">Papua New Guinea</option>
                                                <option value="Paraguay">Paraguay</option>
                                                <option value="Peru">Peru</option>
                                                <option value="Philippines">Philippines</option>
                                                <option value="Pitcairn">Pitcairn</option>
                                                <option value="Poland">Poland</option>
                                                <option value="Portugal">Portugal</option>
                                                <option value="Puerto Rico">Puerto Rico</option>
                                                <option value="Qatar">Qatar</option>
                                                <option value="Réunion">Réunion</option>
                                                <option value="Romania">Romania</option>
                                                <option value="Russian Federation">Russian Federation</option>
                                                <option value="Rwanda">Rwanda</option>
                                                <option value="Swaziland">Swaziland</option>
                                                <option value="Sweden">Sweden</option>
                                                <option value="Switzerland">Switzerland</option>
                                                <option value="Turkey">Turkey</option>
                                                <option value="Turkmenistan">Turkmenistan</option>
                                                <option value="Tuvalu">Tuvalu</option>
                                                <option value="Uganda">Uganda</option>
                                                <option value="Ukraine">Ukraine</option>
                                                <option value="United Kingdom">United Kingdom</option>
                                                <option value="United States">United States</option>
                                                <option value="Uruguay">Uruguay</option>
                                                <option value="Uzbekistan">Uzbekistan</option>
                                                <option value="Yemen">Yemen</option>
                                                <option value="Zambia">Zambia</option>
                                                <option value="Zimbabwe">Zimbabwe</option>
                                            </select>
                                        </div>
                                    </div>
                                    {/* <div className="col-md-3">
                                        <div className="search-job-input">
                                            <div className="ready-to-apply-input">
                                                <button type="submit">Search Candidate</button>
                                            </div>
                                        </div>
                                    </div> */}
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </section>
            <section className="job-listing">
                <div className="container">
                    <div className="row main-candidate-listing-bg">
                        <div className="col-lg-12">
                            <div className="job-display">
                                <div className="row">
                                    <div className="col">
                                        <div className="search-job-input">
                                            <p>Price Per Hour: </p>
                                            <select name="salary" onChange={GetFilter}>
                                                <option>Select Price</option>
                                                <option value="0 - 30">US 30 and below</option>
                                                <option value="30 - 60">US 30 - US 60</option>
                                                <option value="60 - 100000000000">US 60 &amp; Above</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="search-job-input">
                                            <p>Talent Quality: </p>
                                            <select name="distance">
                                                <option value="5 miles"> Top Rated</option>
                                                <option value="5 miles"> Verified </option>
                                                <option value="5 miles" selected> Available</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div className="job-dis-header mt-4">
                                    <div className="jd-header-1">
                                        <h3><span className="filter-category">Candidates</span> In <span className="filter-country">{filter.location === '' ? client.country.name : filter.location}</span></h3>
                                        <p>Displaying <span className="page-limitstart">1</span>-<span className="page-limitend">25</span> of <span className="ttl-filteredjobs">33</span> Jobs
                                        </p>
                                    </div>
                                </div>
                                <div className="main-candidate-listing-sec mt-8">
                                    {isloading === true ? <div className='Loading p-5 h-auto'></div> : <div className="row">
                                        {
                                            candidates.filter((item) => {
                                                return item.first_name.toLowerCase().includes(filter.keywords.toLowerCase()) || item.last_name.toLowerCase().includes(filter.keywords.toLowerCase()) || item.
                                                skills.toLowerCase().includes(filter.keywords.toLowerCase()) || item.
                                                designation.toLowerCase().includes(filter.keywords.toLowerCase())
                                            }).filter((item) => {
                                                return parseInt(item.hourly_rate) >= filter.salary_min && parseInt(item.hourly_rate) <= filter.salary_max
                                            }).map((item) => {
                                                return <div className="col-lg-4"><Candidate_box {...item} /></div>
                                            })
                                        }
                                        <div className="notfound first_d">No more data</div>
                                    </div>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Candidate_Listing