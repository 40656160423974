import axios from 'axios'
import React, { useEffect } from 'react'
import Faqs from '../components/Faqs'
import { useState } from 'react'
import Benefits from '../components/Benefits'
import { Link } from 'react-router-dom'

const Hire_Talent = () => {

    const [cms, cmsData] = useState(null);
    const getData = async () => {
        await axios.get('https://works-wise.demo-customproject.com:4002/get-cms/find_talent').then((res) => {
            cmsData(res.data);
        })
    }

    useEffect(() => {
        getData();
    }, [])

    console.log(cms);

    if (cms === null) {
        return <div className='Loading'></div>
    }

    return (
        <>
            {/* <section className="main_slider inner-banner">
                <div id="carouselExampleControls" className="carousel slide" data-bs-ride="carousel">
                    <div className="carousel-inner">
                        <div className="banner-back">
                            <img src="images/banner-bottom.png" alt="img" />
                        </div>
                        <div className="carousel-item active">
                            <img src="images/banner-front.png" className="img-fluid banner-bg-img" alt="..." />
                            <div className="carousel-caption">
                                <div className="container">
                                    <div className="row">
                                        <div className=" col-sm-6 col-md-6 align-self-center">
                                            <div className="banner_text wow fadeInLeft" data-wow-duration="2s">
                                                <h1>Find Talent
                                                </h1>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}
            <section className="find-talent all-section">
                <div className="container">
                    <div className="row d-flex">
                        <div className="col-lg-6">
                            <div className="find-talent-vector">
                                <img src={`https://works-wise.demo-customproject.com/admin/backend/public/uploads/CMS/${cms.section1.image_path}`} alt />
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="find-talent-content">
                                <h2>{cms.section1.title}</h2>
                                <p>{cms.section1.text1}</p>
                                <a href={cms.section1.button1link} className="apply-job">{cms.section1.button1}</a>
                            </div>
                        </div>
                    </div>
                    <div className="row d-flex flex-direc mt-5">
                        <div className="col-lg-6">
                            <div className="find-talent-content">
                                <h2>{cms.section2.title}</h2>
                                <p>{cms.section2.text1}</p>
                                <a href={cms.section2.button1link} className="apply-job">{cms.section2.button1}</a>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="find-talent-vector">
                                <img src={`https://works-wise.demo-customproject.com/admin/backend/public/uploads/CMS/${cms.section2.image_path}`} alt />
                            </div>
                        </div>
                    </div>
                    <div className="row d-flex mt-5">
                        <div className="col-lg-6">
                            <div className="find-talent-vector">
                                <img src={`https://works-wise.demo-customproject.com/admin/backend/public/uploads/CMS/${cms.section3.image_path}`} alt />
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="find-talent-content">
                                <h2>{cms.section3.title} </h2>
                                <p>{cms.section3.text1}</p>
                                <a href={cms.section3.button1link} className="apply-job">{cms.section3.button1}</a>
                            </div>
                        </div>
                    </div>
                    <div className="row d-flex flex-direc mt-5">
                        <div className="col-lg-6">
                            <div className="find-talent-content">
                                <h2>{cms.section4.title} </h2>
                                <p>{cms.section4.text1}</p>
                                <a href={cms.section4.button1link} className="apply-job">{cms.section4.button1}</a>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="find-talent-vector">
                                <img src={`https://works-wise.demo-customproject.com/admin/backend/public/uploads/CMS/${cms.section4.image_path}`} alt />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Benefits />
            {/* Faq Start */}
            <Faqs />
            <section className="first-step pt-10">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 noLeft">
                            <div className="first-step-img">
                                <img src={`https://works-wise.demo-customproject.com/admin/backend/public/uploads/CMS/${cms.section5.image_path}`} alt />
                            </div>
                        </div>
                        <div className="col-lg-8">
                            <div className="first-step-cntnt">
                                <h3>{cms.section5.title}</h3>
                                <div className="first-step-register">
                                    <ul>
                                        <li>{cms.section5.subtitle}</li>
                                        <li>
                                            <Link to="/sign-up" className="btn btn_badam">{cms.section5.button1}</Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>

    )
}

export default Hire_Talent