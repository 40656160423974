import React, { useState } from 'react'
import axios from 'axios';
import $ from 'jquery';
import { Link, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import secureLocalStorage from "react-secure-storage";

function Login() {

    const navigate = useNavigate();

    useEffect(() => {
        if (secureLocalStorage.getItem('login_data') != null) {
            navigate('/')
        }
    })

    useEffect(() => {
        $(".pasWord ~ i").unbind().click(function () {
            $(this).toggleClass("fa-eye fa-eye-slash");
            let inPut = $(this).parent().find('.pasWord');
            if (inPut.attr('type') === 'password') {
                inPut.attr("type", "text");
            } else {
                inPut.attr("type", "password");
            }
        })
    })

    const [login, setLogin] = useState({
        email: '',
        password: '',
    })

    const setLoginParam = (e) => {
        setLogin((prev) => ({ ...prev, [e.target.name]: e.target.value }));
    }

    const LoginForm = async (e) => {
        e.preventDefault();
        if (login.email != '' && login.password != '') {
            e.target.classList.add('loadin');
            await axios.post('https://works-wise.demo-customproject.com:4002/login', login).then(function (response) {
                if (response.data.status == '200') {
                    $('.msg-box').removeClass('red')
                    $('.msg-box').addClass('green').text(response.data.message);
                    setLogin({});
                    $('input').val('');
                    secureLocalStorage.setItem('login_attmpt', 5);
                    setTimeout(function () {
                        secureLocalStorage.setItem('login_data', JSON.stringify(response.data.data[0]));
                        secureLocalStorage.setItem('accessToken', response.data.accessToken);
                        if(secureLocalStorage.getItem('PreviousURL') === null){
                            window.location.replace('/dashboard/')
                        }else{
                            window.location.replace(secureLocalStorage.getItem('PreviousURL'));
                        }
                    }, 1000)
                } else if (response.data.status == '204') { 
                    $('.msg-box').removeClass('green')
                    $('.msg-box').addClass('red').text(response.data.message);
                    setTimeout(function(){
                        window.location.replace('/forgot-password')
                    },2000)
                }else {
                    e.target.classList.remove('loadin');
                    $('.msg-box').removeClass('green')
                    $('.msg-box').addClass('red').text(response.data.message);
                }
            }).catch(function (error) {
                console.log(error);
            });
        } else {
            e.target.classList.remove('loadin');
            $('.msg-box').removeClass('green')
            $('.msg-box').addClass('red').text('Empty Fields');
        }
    }

    return (
        <>
            <section className="Sign__up">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-6">
                            <div className="signupp_box">
                                <form>
                                <h2>Login</h2>
                                    <div className="row">
                                        <div className="col-lg-12 col-md-12 col-sm-12">
                                            <div className="form">
                                                <input className="input" placeholder="Enter Email Address" type="email" name="email" onChange={setLoginParam} />
                                                <span className="input-border"></span>
                                            </div>
                                        </div>

                                        <div className="col-lg-12 col-md-12 col-sm-12">
                                            <div className="form">
                                                <input className="input pasWord" placeholder="Enter Your Password" type="password" name="password" onChange={setLoginParam} />
                                                <span className="input-border"></span>
                                                <i class="far fa-eye-slash"></i>
                                            </div>
                                        </div>

                                        <div className="col-lg-12 col-md-12 col-sm-12">
                                            <div className='d-flex justify-content-between'>
                                                <button type='submit' onClick={LoginForm}>Login</button>
                                                <Link to="/forgot-password">forgot password</Link>
                                            </div>
                                        </div>
                                        <div className='msg-box'></div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>

    )
}

export default Login
