const ProductReducer = (state, action) => {
    switch (action.type) {
        case "CANDIDATE":
            return {
                ...state,
                candidates: action.payload,
                isloading: false,
            }
        case "COMPANIES":
            return {
                ...state,
                companies: action.payload,
            }
        case "PRICING":
            return {
                ...state,
                pricing: action.payload,
            }
        case "JOBS":
            return {
                ...state,
                jobs: action.payload,
                isloading: false,
            }
        case "AUTH":
            return {
                ...state,
                auth_data: action.payload,
            };
        case "CLIENT":
            return {
                ...state,
                client: action.payload,
            };
        case "IS_LOADING_JOB":
            return {
                ...state,
                isloading: true,
            };
        case "GETLOGO":
            return {
                ...state,
                logos: action.payload,
            };
        case "SITEINFO":
            return {
                ...state,
                site_info: action.payload,
            };
        default:
            return state;
    }
}

export default ProductReducer;