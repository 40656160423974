import React from 'react'
import Company_Box from '../components/Company_Box'
import { useProductContext } from '../context/productcontext';
import { useState } from 'react';

const Company_Listing = () => {
    const { companies } = useProductContext();
    const [filter, setFilter] = useState('');
    if (companies === null) {
        return <div className='Loading'></div>
    }
    return (
        <>
            <section className="search-job">
                <div className="container">
                    <div className="row search-job-bg">
                        <div className="col-lg-12">
                            <form>
                                <div className="search-heading">
                                    <h3>Search Known Company</h3>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        <div className="search-job-input">
                                            <input type="text" name="job-title" placeholder="Search..." onChange={(e) => {setFilter(e.target.value)}} />
                                        </div>
                                    </div>
                                    {/* <div className="col-md-3">
                                        <div className="search-job-input">
                                            <div className="ready-to-apply-input">
                                                <button type="submit">Search Company</button>
                                            </div>
                                        </div>
                                    </div> */}
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </section>
            <section className="job-listing">
                <div className="container">
                    <div className="row main-candidate-listing-bg">
                        <div className="col-lg-12">
                            <div className="company-display">
                                <div className="job-dis-header mt-4">
                                    <div className="jd-header-1">
                                        <h3><span className="filter-category">Companies</span> In <span className="filter-country">London</span></h3>
                                        <p>Displaying <span className="page-limitstart">1</span>-<span className="page-limitend">25</span> of <span className="ttl-filteredjobs">33</span> Jobs</p>
                                    </div>
                                    <div className="jd-header-2">
                                        <div className="jd-pagination">
                                            <label htmlFor="results-ppage">Results per page: </label>
                                            <select name="results-ppage" id="results-ppage">
                                                <option value={10}>10</option>
                                                <option value={25} selected>25</option>
                                                <option value={50}>50</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div className="main-candidate-listing-sec">
                                    <div className="row">
                                        {
                                            companies.filter((item) => {
                                                return item.company_name.toLowerCase().includes(filter.toLowerCase());
                                            }).map((comp) => {
                                                return <div className="col-lg-3">
                                                    <Company_Box {...comp} />
                                                </div>
                                            })
                                        }
                                        <div className="notfound first_d mt-5">No more data</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default Company_Listing