import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import Slider from "react-slick";
const renderHTML = (escapedHTML) => React.createElement("div", { dangerouslySetInnerHTML: { __html: escapedHTML } });

const About = () => {


  const [cms, cmsData] = useState(null);

  const getData = async () => {
    await axios.get('https://works-wise.demo-customproject.com:4002/get-cms/about').then((res) => {
      cmsData(res.data);
    })
  }

  useEffect(() => {
    getData();
  }, [])

  const option = {
    dots: false,
    arrows: false,
    infinite: true,
    autoplay: true,
    // speed: 300,
    slidesToShow: 5,
    slidesToScroll: 1,
    speed: 6000,
    autoplaySpeed: 1,
    autoplay: true,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  }

  if (cms === null) {
    return <div className='Loading'></div>
  }

  return (
    <>
      {/* <section className="main_slider inner-banner">
        <div id="carouselExampleControls" className="carousel slide" data-bs-ride="carousel">
          <div className="carousel-inner">
            <div className="banner-back">
              <img src="images/banner-bottom.png" alt="img" />
            </div>
            <div className="carousel-item active">
              <img src="images/banner-front.png" className="img-fluid banner-bg-img" alt="..." />
              <div className="carousel-caption">
                <div className="container">
                  <div className="row">
                    <div className=" col-sm-6 col-md-6 align-self-center">
                      <div className="banner_text wow fadeInLeft" data-wow-duration="2s">
                        <h1>About Us
                        </h1>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}
      <section className="abt-section all-section">
        <div className="container">
          <div className="row align-items-end">
            <div className="col-lg-6">
              <div className="abt-sec-1">
                {/* <div className="abt-imgs">
                  <img src="images/abt-1.jpg" alt="img" className="abt-img-1 wow fadeInRight" />
                  <img src="images/abt-2.jpg" alt className="abt-img-2 wow fadeInUp" />
                </div> */}
                <img src={`https://works-wise.demo-customproject.com/admin/backend/public/uploads/CMS/${cms.about_section.image_path}`} />
                <div className="abt-img-text wow fadeInLeft">
                  <p>{cms.about_section.text2}</p>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="sec-heading abt-content wow fadeInLeft">
                <h4>{cms.about_section.subtitle}</h4>
                <h2>{cms.about_section.title}</h2>
                <p>{cms.about_section.text1}</p>
              </div>
            </div>
          </div>
          <div className="row mt-5">
            <div className="col-lg-6">
              <div className="mis-vis">
                <h3>{cms.mission.title}</h3>
                <p>{cms.mission.text1}</p>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="mis-vis">
                <h3>{cms.vision.title}</h3>
                <p>{cms.vision.text1}</p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="what-makeus-stand pb-10">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="wmso-heading text-center sec-heading">
                <h2>{cms.section2.title}</h2>
              </div>
            </div>
            <div className="col-lg-2">
              <div className="cv-achievements">
                <img
                  src={`https://works-wise.demo-customproject.com/admin/backend/public/uploads/CMS/${cms.section2_item1.image_path}`}
                  alt />
                {renderHTML(cms.section2_item1.description)}
              </div>
            </div>
            <div className="col-lg-2">
              <div className="achievment-wmus">
                <div className="achievment-wmus-img">
                  <img src={`https://works-wise.demo-customproject.com/admin/backend/public/uploads/CMS/${cms.section2_item2.image_path}`} alt />
                </div>
                {renderHTML(cms.section2_item2.description)}
              </div>
            </div>
            <div className="col-lg-2">
              <div className="achievment-wmus">
                <div className="achievment-wmus-img">
                  <img src={`https://works-wise.demo-customproject.com/admin/backend/public/uploads/CMS/${cms.section2_item3.image_path}`} alt />
                </div>
                {renderHTML(cms.section2_item3.description)}
              </div>
            </div>
            <div className="col-lg-2">
              <div className="achievment-wmus">
                <div className="achievment-wmus-img">
                  <img src={`https://works-wise.demo-customproject.com/admin/backend/public/uploads/CMS/${cms.section2_item4.image_path}`} alt />
                </div>
                {renderHTML(cms.section2_item4.description)}
              </div>
            </div>
            <div className="col-lg-2">
              <div className="achievment-wmus">
                <div className="achievment-wmus-img">
                  <img src={`https://works-wise.demo-customproject.com/admin/backend/public/uploads/CMS/${cms.section2_item5.image_path}`} alt />
                </div>
                {renderHTML(cms.section2_item4.description)}
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="top-recuriter">
        <div className="container">
          <div className="row">
            <div className="col-md-10 centerCol">
              <div className="text-center sec-heading">
                <h2>{cms.section3.title}</h2>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 wow fadeInRight" data-wow-duration="2s">
            <Slider {...option} className="top-recuriter-slider pt-3 pb-3">
              <div>
                <div className="top-recuriter-slider-item">
                  <img src="images/sap-logo.png" alt />
                </div>
              </div>
              <div>
                <div className="top-recuriter-slider-item">
                  <img src="images/oracle-logo.png" alt />
                </div>
              </div>
              <div>
                <div className="top-recuriter-slider-item">
                  <img src="images/peoplesoft-logo.png" alt />
                </div>
              </div>
              <div>
                <div className="top-recuriter-slider-item">
                  <img src="images/aws-logo.png" alt />
                </div>
              </div>
              <div>
                <div className="top-recuriter-slider-item">
                  <img src="images/azure-logo.png" alt />
                </div>
              </div>
              <div>
                <div className="top-recuriter-slider-item">
                  <img src="images/jde-logo.png" alt />
                </div>
              </div>
              <div>
                <div className="top-recuriter-slider-item">
                  <img src="images/salesforce-logo.png" alt />
                </div>
              </div>
            </Slider>
          </div>
        </div>
      </section>
      <section className="locate-sec">
        <div className="container">
          <div className="row">
            <div className="col-md-7 centerCol">
              <div className="sec-heading categ-hd text-center wow fadeInDown">
                <h4>{cms.section4.subtitle}</h4>
                <h2>{cms.section4.title}</h2>
                <p>{cms.section4.text1} </p>
              </div>
            </div>
          </div>
          <div className="row mt-5">
            <div className="col-md-12">
              <div className="regions-locate">
                <div className="row pt-3 pb-3">
                  <div className="col-md-4">
                    <div className="middle-east region-locate-item">
                      <h4>Middle<span className="d-block">East</span></h4>
                      <img src="images/abt-location-marker.png" alt="location-market" />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="uk region-locate-item">
                      <img src="images/abt-location-marker.png" alt="location-market" />
                      <h4>UK</h4>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="denmark region-locate-item ">
                      <img src="images/abt-location-marker.png" alt="location-market" />
                      <h4>Denmark</h4>
                    </div>
                  </div>
                </div>
                <div className="row justify-content-end pt-3 pb-5">
                  <div className="col-md-6">
                    <div className="india region-locate-item  justify-content-end">
                      <h4>India</h4>
                      <img src="images/abt-location-marker.png" alt="location-market" />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="estonia region-locate-item  justify-content-end">
                      <img src="images/abt-location-marker.png" alt="location-market" />
                      <h4>Estonia</h4>
                    </div>
                  </div>
                </div>
                <div className="row justify-content-end pt-5 pb-3">
                  <div className="col-md-4">
                    <div className="france region-locate-item justify-content-end">
                      <img src="images/abt-location-marker.png" alt="location-market" />
                      <h4>France</h4>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="germany region-locate-item justify-content-end">
                      <img src="images/abt-location-marker.png" alt="location-market" />
                      <h4>Germany</h4>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="finland region-locate-item justify-content-end">
                      <img src="images/abt-location-marker.png" alt="location-market" />
                      <h4>Finland</h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

    </>

  )
}

export default About