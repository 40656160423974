import axios from "axios";
import { createContext, useContext, useEffect, useReducer } from "react";
import reducer from "../reducer/ProductReducer"
import secureLocalStorage from "react-secure-storage";
import $ from 'jquery'

const AppContext = createContext();

const initailState = {
    isloading: false,
    isloadingDet: false,
    isError: false,
    candidates: null,
    companies: null,
    pricing: null,
    jobs: null,
    client: null,
    logos: null,
    site_info: null,
}


const AppProvider = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, initailState);
    const AuthData = () => {
        dispatch({ type: "IS_LOADING_AUTH" })
        try {
            const auth = JSON.parse(secureLocalStorage.getItem('login_data'));
            dispatch({ type: "AUTH", payload: auth });
        } catch (error) {
            dispatch({ type: "IS_ERROR_AUTH" })
        }
    }
    // Candidate Data
    const getCandidates = async (country) => {
        dispatch({ type: "IS_LOADING_JOB" })
        try {
            const res = await axios.post('https://works-wise.demo-customproject.com:4002/candidate-listing/', { country: country });
            const candidate = await res.data;
            dispatch({ type: "CANDIDATE", payload: candidate });
        } catch (error) {
            dispatch({ type: "IS_ERROR_CAT" })
        }
    }
    // Candidate Data
    // Companies Data
    const getCompanies = async (url) => {
        dispatch({ type: "IS_LOADING_CAT" })
        try {
            const res = await axios.get(url);
            const companies = await res.data;
            dispatch({ type: "COMPANIES", payload: companies });
        } catch (error) {
            dispatch({ type: "IS_ERROR_CAT" })
        }
    }
    // Companies Data
    // Pricing Data
    const getPricing = async (url) => {
        dispatch({ type: "IS_LOADING_CAT" })
        try {
            const res = await axios.get(url);
            const pricing = await res.data;
            dispatch({ type: "PRICING", payload: pricing });
        } catch (error) {
            dispatch({ type: "IS_ERROR_CAT" })
        }
    }
    // Pricing Data

    const JOB_API = "https://works-wise.demo-customproject.com:4002/all-jobs/"
    const getJobs = async (country) => {
        dispatch({ type: "IS_LOADING_JOB" })
        try {
            const res = await axios.post(JOB_API, { country: country });
            const jobs = await res.data;
            dispatch({ type: "JOBS", payload: jobs });
        } catch (error) {
            dispatch({ type: "IS_ERROR_CAT" })
        }
    }

    const getlogo = async (url) => {
        dispatch({ type: "IS_LOADING_JOB" })
        try {
            const res = await axios.get(url);
            const logos = await res.data;
            $('link[rel="icon"]').attr('href','https://works-wise.demo-customproject.com/admin/backend/public/uploads/'+res.data[1].img_path);
            dispatch({ type: "GETLOGO", payload: logos });
        } catch (error) {
            dispatch({ type: "IS_ERROR_CAT" })
        }
    }

    const SiteInfo = async (url) => {
        dispatch({ type: "IS_LOADING_JOB" })
        try {
            const res = await axios.get(url);
            const site_info = await res.data;
            dispatch({ type: "SITEINFO", payload: site_info });
        } catch (error) {
            dispatch({ type: "IS_ERROR_CAT" })
        }
    }
    // site-info
    
    // const getJobsScroll = async (add) => {
    //     dispatch({ type: "IS_LOADING_JOB" })
    //     try {
    //         const res = await axios.post(JOB_API, { scroll: add, country: client_country });
    //         const jobs = await res.data;
    //         dispatch({ type: "JOBS", payload: jobs });
    //     } catch (error) {
    //         dispatch({ type: "IS_ERROR_CAT" })
    //         console.log(error)
    //     }
    // }

    const userLocation = async () => {
        dispatch({ type: "IS_LOADING_CAT" })
        try {
            const res = await axios.get(`https://api.geoapify.com/v1/ipinfo?&apiKey=f87c4e07ee824ccfa5b2bcf348a67b8a`);
            const client = await res.data;
            const user = JSON.parse(secureLocalStorage.getItem('login_data'))
            if(user?.role == 0){
                GetNewJobs();
            }else{
                getJobs(res.data.country.name);
            }
            getCandidates(res.data.country.name);
            dispatch({ type: "CLIENT", payload: client });
        } catch (error) {
            dispatch({ type: "IS_ERROR_CAT" })
        }
    }

    const GetNewJobs = async () => {
        dispatch({ type: "IS_LOADING_CAT" })
        try {
            const res = await axios.get(`https://works-wise.demo-customproject.com:4002/get-new-job/${JSON.parse(secureLocalStorage.getItem('login_data')).id}`, { headers: { 'Auth': secureLocalStorage.getItem('accessToken') } });
            const jobs = await res.data;
            dispatch({ type: "JOBS", payload: jobs });
        } catch (error) {
            dispatch({ type: "IS_ERROR_CAT" })
        }
    }


    useEffect(() => {
        AuthData();
        getCompanies('https://works-wise.demo-customproject.com:4002/company-listing/');
        getPricing('https://works-wise.demo-customproject.com:4002/get-pricing/');
        userLocation();
        getlogo('https://works-wise.demo-customproject.com:4002/getlogo')
        SiteInfo('https://works-wise.demo-customproject.com:4002/site-info')
    }, [])

    return <AppContext.Provider value={{ ...state, getJobs, getCandidates }} > {children} </AppContext.Provider>
};

const useProductContext = () => {
    return useContext(AppContext);
}
export { AppProvider, AppContext, useProductContext };