import axios from "axios";
import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { useProductContext } from "../context/productcontext";
import secureLocalStorage from "react-secure-storage";
import Moment from 'react-moment';
import $ from 'jquery';
const renderHTML = (escapedHTML) => React.createElement("div", { dangerouslySetInnerHTML: { __html: escapedHTML } });


const Job_Description = () => {
    const { id } = useParams();
    const [data, setData] = useState(null);
    const [related, setRelated] = useState(null);
    const [applied, setApplied] = useState(false);
    const { auth_data } = useProductContext();
    useEffect(() => {
        getData();
        CheckApp();
    }, [id])
    const getData = async () => {
        await axios.get(`https://works-wise.demo-customproject.com:4002/get-single-jobs/${id}`).then((res) => {
            setData(res.data[0]);
            setRelated(res.data.related);
        })
    }
    const CheckApp = async () => {
        if (auth_data.role == 0) {
            await axios.post(`https://works-wise.demo-customproject.com:4002/check-applied/`, { user_id: auth_data.id, job_id: parseInt(id) }, { headers: { 'Auth': secureLocalStorage.getItem('accessToken') } }).then((res) => {
                setApplied(res.data.applied);
            })
        }
    }

    const saveJob = async (e) => {
        e.preventDefault();
        await axios.post(`https://works-wise.demo-customproject.com:4002/saveJob/`, { user_id: auth_data.id, job_id: parseInt(id) }, { headers: { 'Auth': secureLocalStorage.getItem('accessToken') } }).then((res) => {
            if (res.data.status == '200') {
                $('.msg-box').removeClass('red')
                $('.msg-box').addClass('green').text(res.data.message);
                e.target.classList.remove('loadin');
                setTimeout(function () {
                    $('.msg-box').removeClass('green').text('');
                }, 2000)
            } else {
                e.target.classList.remove('loadin');
                $('.msg-box').removeClass('green')
                $('.msg-box').addClass('red').text(res.data.message);
            }
        })
    }

    const ApplyJob = async (e) => {
        e.preventDefault();
        e.target.classList.add('loadin');
        await axios.post(`https://works-wise.demo-customproject.com:4002/apply-for-job/`, { user_id: auth_data.id, job_id: parseInt(id) }, { headers: { 'Auth': secureLocalStorage.getItem('accessToken') } }).then((res) => {
            if (res.data.status == '200') {
                $('.msg-box').removeClass('red')
                $('.msg-box').addClass('green').text(res.data.message);
                e.target.classList.remove('loadin');
            } else {
                e.target.classList.remove('loadin');
                $('.msg-box').removeClass('green')
                $('.msg-box').addClass('red').text(res.data.message);
            }
        })
    }

    if (data === null && related === null) {
        return <div className='Loading'></div>
    }
    return (
        <div>
            <section className="job-desc pt-5">
                <div className="container">
                    <div className="row justify-content-between">
                        <div className="col-lg-7">
                            <div className="job-detailed-desc">
                                <h2 className="job-desc-heading">{data.title}</h2>
                                <ul className="short-desc">
                                    <li>
                                        <div className="short-desc-loca">
                                            <i class="fa-regular fa-location-dot"></i>
                                            <span>{data.location}</span>
                                        </div>
                                        <div className="short-desc-loca">
                                            <i class="fa-regular fa-briefcase"></i>
                                            <span>{data.type}</span>
                                        </div>
                                    </li>
                                </ul>
                                <ul className="date-jbhytb">
                                    <li><i className="icon-material-outline-date-range" />
                                        Posted on <Moment format="DD MMMM, YYYY">{data.created_at}</Moment></li>
                                    <li><i className="icon-material-outline-date-range" />
                                        Expiring on <Moment format="DD MMMM, YYYY">{data.date}</Moment></li>
                                </ul>
                                <hr />
                                <div className="jobpost-salary"><span>Salary</span> {data.salary_min} <sub>{data.currency}</sub> - {data.salary_max} <sub>{data.currency}</sub></div>
                                <p>{data.short_discription}</p>
                                <div className="job-desc-act-btn">
                                    {
                                        applied === true ? <div className="msg-box green m-0">Your application has been sent please wait for the response</div> : (
                                            auth_data === null ? <a href="/login" className="apply-now">Apply Now</a> :
                                                (
                                                    auth_data.role != 0 ? false :
                                                        (
                                                            data.redirect_url ? <a href={data.redirect_url} target="_blank" className="apply-now">Apply Now</a> : <a href="#" onClick={ApplyJob} className="apply-now">Apply Now</a>
                                                        )
                                                )
                                        )
                                    }
                                    {
                                        auth_data === null ? <Link to="/login" className="save-job">Save</Link> : ( auth_data?.role != 0 ? false : <a href="#" className="save-job" onClick={saveJob}>Save</a>)
                                    }
                                </div>
                                <div className="msg-box"></div>
                                <div className="discripton-detla">
                                    {renderHTML(data.discription)}
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="job-desc-org-det">
                                <div className="compny-logo-main">
                                    {
                                        data.profile_pic === null ? <i class="fas fa-briefcase"></i> : <img src={`https://works-wise.demo-customproject.com/admin/backend/public/uploads/users/${data.profile_pic}`} alt />
                                    }
                                </div>
                                <ul>
                                    <li className="job-desc-company-name"><Link to={`/company-profile/${data.company_id}`}>{data.company_name}</Link></li>
                                    <li className="job-desc-company-type">{data.industry}</li>
                                </ul>
                            </div>
                            <div className="skills-prjv">
                                <h3>Skills Required</h3>
                                {
                                    data.skills.split(',').map((itm) => {
                                        return <span>{itm}</span>
                                    })
                                }
                            </div>
                            <div className="related-jobs">
                                <h3>Related Jobs</h3>
                                <div className="row">
                                    {
                                        related.map((jb) => {
                                            return <div className="col-xl-12 col-lg-12 col-6">
                                                <div className="related-jobs job-post-item">
                                                    <Link to={`/job-description/${jb.id}`}>
                                                        <h2>{jb.title}</h2>
                                                    </Link>
                                                    <div className="jobpost-content">
                                                        <p>{jb.short_discription}</p>
                                                    </div>
                                                    <div className="jobpost-act-btn">
                                                        <Link to={`/job-description/${jb.id}`} className="apply-job">Apply Now</Link>
                                                    </div>
                                                </div>
                                            </div>
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>


    )
}

export default Job_Description