import React, { useEffect } from 'react'
import Slider from "react-slick";
import $ from 'jquery';
import axios from 'axios';
import { Link } from 'react-router-dom';
import Lottie from 'react-lottie';
import animationData from '../components/gif.json';
import { useState } from 'react';

const Home = () => {

    const [cms, cmsData] = useState(null);

    const getData = async () => {
        await axios.get('https://works-wise.demo-customproject.com:4002/get-cms/home').then((res) => {
            cmsData(res.data);
        })
    }

    // console.log(cms)

    const lottie_Options = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    };

    useEffect(() => {
        getData();
    }, [])

    const option = {
        dots: false,
        arrows: false,
        infinite: true,
        autoplay: true,
        // speed: 300,
        slidesToShow: 5,
        slidesToScroll: 1,
        speed: 6000,
        autoplaySpeed: 1,
        autoplay: true,
        cssEase: "linear",
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 575,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                },
            },
        ],
    }

    useEffect(() => {
        var words = document.getElementsByClassName('word');
        var wordArray = [];
        var currentWord = 0;
        if (words.length !== 0) {
            words[currentWord].style.opacity = 1;
            for (var i = 0; i < words.length; i++) {
                splitLetters(words[i]);
            }

            function changeWord() {
                var cw = wordArray[currentWord];
                var nw = currentWord == words.length - 1 ? wordArray[0] : wordArray[currentWord + 1];
                for (var i = 0; i < cw.length; i++) {
                    animateLetterOut(cw, i);
                }

                for (var i = 0; i < nw.length; i++) {
                    nw[i].className = 'letter behind';
                    nw[0].parentElement.style.opacity = 1;
                    animateLetterIn(nw, i);
                }

                currentWord = (currentWord == wordArray.length - 1) ? 0 : currentWord + 1;
            }

            function animateLetterOut(cw, i) {
                setTimeout(function () {
                    cw[i].className = 'letter out';
                }, i * 80);
            }

            function animateLetterIn(nw, i) {
                setTimeout(function () {
                    nw[i].className = 'letter in';
                }, 340 + (i * 80));
            }

            function splitLetters(word) {
                var content = word.innerHTML;
                word.innerHTML = '';
                var letters = [];
                for (var i = 0; i < content.length; i++) {
                    var letter = document.createElement('span');
                    letter.className = 'letter';
                    letter.innerHTML = content.charAt(i);
                    word.appendChild(letter);
                    letters.push(letter);
                }

                wordArray.push(letters);
            }

            changeWord();
            setInterval(changeWord, 4000);
        }

    })

    if (cms === null) {
        return <div className='Loading'></div>
    }

    return (
        <div>
            <section className="main_slider">
                <div className="banner-back">
                    <img src="images/banner-bottom.png" alt="img" />
                </div>
                <div className="main-banner-bgvideo">
                    {
                        (() => {
                            let Src = cms.home_banner.image_path;
                            let ext = Src.split('.')[Src.split('.').length - 1];
                            let vid_files = ["mp4", "mov", "WebM", "AVI", "WMV"];
                            if (vid_files.includes(ext)) {
                                return <video muted loop autoPlay>
                                    <source src={`https://works-wise.demo-customproject.com/admin/backend/public/uploads/CMS/${cms.home_banner.image_path}`} type="video/mp4" />
                                </video>
                            } else {
                                return <img src={`https://works-wise.demo-customproject.com/admin/backend/public/uploads/CMS/${cms.home_banner.image_path}`} />
                            }
                        })()
                    }

                    <div className="banner-content">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-10 centerCol">
                                    <div className="banner_text text-center">
                                        <h1>{cms.home_banner.title}
                                            <span className="d-block">
                                                {
                                                    cms.home_banner.subtitle.split(',').map((item) => {
                                                        return <span className="word wisteria">{item}</span>
                                                    })
                                                }
                                            </span>
                                        </h1>
                                        <p>{cms.home_banner.text1}</p>
                                        <Link to={cms.home_banner.button1link} className="btn btn_badam">{cms.home_banner.button1}</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section >
            <section className="emplyrs-platform pt-10 mt-5">
                <div className="container">
                    <div className="row justify-content-center d-flex">
                        <div className="col-lg-5">
                            <div className="emp-plat-gif">
                                {
                                    <img src={`https://works-wise.demo-customproject.com/admin/backend/public/uploads/CMS/${cms.section2.image_path}`} />
                                }
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="emp-plat-content">
                                <h3>{cms.section2.title}</h3>
                                <ul>
                                    <li><img src={`https://works-wise.demo-customproject.com/admin/backend/public/uploads/CMS/${cms.section2_item1.image_path}`} alt /></li>
                                    <li>
                                        <h4>{cms.section2_item1.title}</h4>
                                        <p>{cms.section2_item1.subtitle}</p>
                                    </li>
                                </ul>
                                <ul>
                                    <li><img src={`https://works-wise.demo-customproject.com/admin/backend/public/uploads/CMS/${cms.section2_item2.image_path}`} alt /></li>
                                    <li>
                                        <h4>{cms.section2_item2.title}</h4>
                                        <p>{cms.section2_item2.subtitle}</p>
                                    </li>
                                </ul>
                                <ul>
                                    <li><img src={`https://works-wise.demo-customproject.com/admin/backend/public/uploads/CMS/${cms.section2_item3.image_path}`} alt /></li>
                                    <li>
                                        <h4>{cms.section2_item3.title}</h4>
                                        <p>{cms.section2_item3.subtitle}</p>
                                    </li>
                                </ul>
                                <div className="emp-plat-btns">
                                    <Link className="btn1 sign-up-emp-plat" to={cms.section2.button1link}>
                                        <span>{cms.section2.button1}</span>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="approach-module module seo-module all-section">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="sec-heading text-center">
                                <h2>{cms.section3.title}</h2>
                                <p>{cms.section3.subtitle}</p>
                            </div>
                        </div>
                    </div>
                    <div className="tab-content" id="pills-tabContent">
                        <div className="tab-pane fade show active" id="pills-empJourney" role="tabpanel" aria-labelledby="pills-empJourney-tab">
                            <div className="approach-items">
                                <div className="approach-item">
                                    <div className="approach-inner">
                                        <span className="approach-title">
                                            <img className="invert-img" src={`https://works-wise.demo-customproject.com/admin/backend/public/uploads/CMS/${cms.section3_box1.image_path}`} alt="icon" />
                                            {cms.section3_box1.title}
                                        </span>
                                        <div className="approach-content">
                                            <p>{cms.section3_box1.subtitle}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="approach-item">
                                    <div className="approach-inner">
                                        <span className="approach-title">
                                            <img className="invert-img" src={`https://works-wise.demo-customproject.com/admin/backend/public/uploads/CMS/${cms.section3_box2.image_path}`} alt="icon" />
                                            {cms.section3_box2.title}
                                        </span>
                                        <div className="approach-content_3">
                                            <p>{cms.section3_box2.subtitle}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="approach-item">
                                    <div className="approach-inner">
                                        <span className="approach-title">
                                            <img className="invert-img" src={`https://works-wise.demo-customproject.com/admin/backend/public/uploads/CMS/${cms.section3_box3.image_path}`} alt="icon" />
                                            {cms.section3_box3.title}
                                        </span>
                                        <div className="approach-content_4">
                                            <p>{cms.section3_box3.subtitle}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="top-technology">
                <div className="container">
                    <div className="row">
                        <div className="col-md-10 centerCol">
                            <div className="text-center sec-heading">
                                <h2>{cms.section4.title}</h2>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12 wow fadeInRight" data-wow-duration="2s">
                        <Slider {...option} className="top-recuriter-slider pt-3 pb-3">
                            <div>
                                <div className="top-recuriter-slider-item">
                                    <img src="images/sap-logo.png" alt />
                                </div>
                            </div>
                            <div>
                                <div className="top-recuriter-slider-item">
                                    <img src="images/oracle-logo.png" alt />
                                </div>
                            </div>
                            <div>
                                <div className="top-recuriter-slider-item">
                                    <img src="images/peoplesoft-logo.png" alt />
                                </div>
                            </div>
                            <div>
                                <div className="top-recuriter-slider-item">
                                    <img src="images/aws-logo.png" alt />
                                </div>
                            </div>
                            <div>
                                <div className="top-recuriter-slider-item">
                                    <img src="images/azure-logo.png" alt />
                                </div>
                            </div>
                            <div>
                                <div className="top-recuriter-slider-item">
                                    <img src="images/jde-logo.png" alt />
                                </div>
                            </div>
                            <div>
                                <div className="top-recuriter-slider-item">
                                    <img src="images/salesforce-logo.png" alt />
                                </div>
                            </div>
                        </Slider>
                    </div>
                </div>
            </section>
            <section className="diversity-sec all-section">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-10 centerCol">
                            <div className="diversity-heading">
                                <h2>{cms.section5.title}</h2>
                                <p>{cms.section5.subtitle}</p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-10 centerCol">
                                    <div className="diversity-sec-tb">
                                        <div className="d-flex align-items-start justify-content-center">
                                            <div className="nav flex-column nav-pills me-3" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                                                <button className="nav-link active" id="v-pills-profile-tab" data-bs-toggle="pill" data-bs-target="#v-pills-profile" type="button" role="tab" aria-controls="v-pills-profile" aria-selected="true">
                                                    {cms.section5_tab1.button1}
                                                </button>
                                                <button className="nav-link " id="v-pills-home-tab" data-bs-toggle="pill" data-bs-target="#v-pills-home" type="button" role="tab" aria-controls="v-pills-home" aria-selected="false">
                                                    {cms.section5_tab2.button1}
                                                </button>
                                                <button className="nav-link" id="v-pills-messages-tab" data-bs-toggle="pill" data-bs-target="#v-pills-messages" type="button" role="tab" aria-controls="v-pills-messages" aria-selected="false">
                                                    {cms.section5_tab3.button1}
                                                </button>
                                            </div>
                                            <div className="tab-content" id="v-pills-tabContent">
                                                <div className="tab-pane fade show active" id="v-pills-profile" role="tabpanel" aria-labelledby="v-pills-profile-tab">
                                                    <div className="diversity-sec-tb-txt">
                                                        <h4>{cms.section5_tab1.title}</h4>
                                                        <p>{cms.section5_tab1.subtitle}</p>
                                                    </div>
                                                </div>
                                                <div className="tab-pane fade " id="v-pills-home" role="tabpanel" aria-labelledby="v-pills-home-tab">
                                                    <div className="diversity-sec-tb-txt">
                                                        <h4>{cms.section5_tab2.title}</h4>
                                                        <p>{cms.section5_tab2.subtitle}</p>
                                                    </div>
                                                </div>
                                                <div className="tab-pane fade" id="v-pills-messages" role="tabpanel" aria-labelledby="v-pills-messages-tab">
                                                    <div className="diversity-sec-tb-txt">
                                                        <h4>{cms.section5_tab3.title}</h4>
                                                        <p>{cms.section5_tab2.subtitle}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="for-employers pb-10">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 noLeft">
                            <div className="for-emp-img">
                                <img src={`https://works-wise.demo-customproject.com/admin/backend/public/uploads/CMS/${cms.section6.image_path}`} alt />
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="for-emp-content">
                                <div className="for-emp-main-content">
                                    <h5>{cms.section6.subtitle}</h5>
                                    <h2>{cms.section6.title}</h2>
                                    <p>{cms.section6.text1}</p>
                                </div>
                                <ul>
                                    <li>{cms.section6.text2}</li>
                                    <li>{cms.section6.text3}</li>
                                    <li>{cms.section6.text4}</li>
                                </ul>
                                <Link to={cms.section6.button1link} className="btn btn_badam">{cms.section6.button1}</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section class="top-recuriter">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-md-10 centerCol">
                            <div class="top-recuriter-hd text-center">
                                <h2>{cms.section7.title}</h2>
                            </div>
                        </div>

                        <div class="col-md-12 wow fadeInRight" data-wow-duration="2s">
                            <Slider {...option} class="top-recuriter-slider pt-3 pb-3">
                                <div>
                                    <div class="top-recuriter-slider-item">
                                        <img src="images/recruiter-1.jpg" alt="" />
                                    </div>
                                </div>

                                <div>
                                    <div class="top-recuriter-slider-item">
                                        <img src="images/recruiter-2.jpg" alt="" />
                                    </div>
                                </div>

                                <div>
                                    <div class="top-recuriter-slider-item">
                                        <img src="images/recruiter-3.jpg" alt="" />
                                    </div>
                                </div>

                                <div>
                                    <div class="top-recuriter-slider-item">
                                        <img src="images/recruiter-4.jpg" alt="" />
                                    </div>
                                </div>

                                <div>
                                    <div class="top-recuriter-slider-item">
                                        <img src="images/recruiter-5.jpg" alt="" />
                                    </div>
                                </div>

                                <div>
                                    <div class="top-recuriter-slider-item">
                                        <img src="images/recruiter-1.jpg" alt="" />
                                    </div>
                                </div>

                                <div>
                                    <div class="top-recuriter-slider-item">
                                        <img src="images/recruiter-2.jpg" alt="" />
                                    </div>
                                </div>

                                <div>
                                    <div class="top-recuriter-slider-item">
                                        <img src="images/recruiter-3.jpg" alt="" />
                                    </div>
                                </div>

                                <div>
                                    <div class="top-recuriter-slider-item">
                                        <img src="images/recruiter-4.jpg" alt="" />
                                    </div>
                                </div>

                                <div>
                                    <div class="top-recuriter-slider-item">
                                        <img src="images/recruiter-5.jpg" alt="" />
                                    </div>
                                </div>
                            </Slider>
                        </div>
                    </div>
                </div>
            </section>
        </div >
    )
}

export default Home
