import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import $ from 'jquery'

export default function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
    $('body').addClass('loading-body');
    $('.mobile-nav').removeClass('open');
    setTimeout(() => {
      $('body').removeClass('loading-body');
    }, 1000)
    var str = window.location.href.toLowerCase();
    $("#menu>li>a").each(function () {
      if (str.indexOf(this.href.toLowerCase()) > -1) {
        $("#menu li.active").removeClass("active");
        $(this).parent().addClass("active");
      }
    });
    $("#menu>li>a").click(function () {
      $("#menu li.active").removeClass("active");
      $(this).parent().addClass("active");
    });
  }, [pathname]);

  return null;
}
