import { useParams } from 'react-router-dom'
import React, { useState, useEffect } from 'react'
import axios from 'axios';
import Moment from 'react-moment';
import TimeAgo from '../components/TimeAgo';
import { Link } from 'react-router-dom'
import secureLocalStorage from "react-secure-storage";
import { useProductContext } from '../context/productcontext';

const Company_Profile = () => {
    const [data, setData] = useState(null);
    const [jobs, setJobs] = useState(null);
    const [mark, setBookMark] = useState(null);
    const { id } = useParams();
    const { auth_data } = useProductContext();



    const GetCompany = async () => {
        await axios.get(`https://works-wise.demo-customproject.com:4002/get-company-data/${id}`).then(res => {
            if (res.data.data.length !== 0) {
                setData(res.data.data[0]);
                setJobs(res.data.jobs)
            }
        })
    }

    const GetBookMark = async () => {
        if (auth_data?.role == '0') {
            await axios.get(`https://works-wise.demo-customproject.com:4002/get-follow-author/${id}/${auth_data?.id}/?asdas`).then(res => {
                setBookMark(res.data[0].status);
            })
        }
    }

    const BookMark = async (e) => {
        e.preventDefault();
        if (auth_data?.role == '0') {
            let API = "";
            if (mark == 0) {
                API = "follow-author"
            } else {
                API = "unfollow-author"
            }
            await axios.get(`https://works-wise.demo-customproject.com:4002/${API}/${id}/${auth_data?.id}/?aa`, { headers: { 'Auth': secureLocalStorage.getItem('accessToken') } }).then(res => {
                GetBookMark();
            })
        }
    }

    useEffect(() => {
        GetCompany();
    }, [id])
    useEffect(() => {
        GetBookMark();
    }, [id, auth_data])

    if (data === null || jobs === null) {
        return <div className='Loading'></div>
    }
    return (
        <>
            <section className="company-profile">
                <div className="container">
                    <div className="about-company mt-4 mb-4">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="company-profile-heading d-flex justify-content-between align-items-start">
                                    <h2><img src="images/company-profile-icon.png" alt /> About Company</h2>
                                    {auth_data?.role == '0' ? <button onClick={BookMark} className={`bookmark-btn ${mark == 1 ? 'active' : false}`}>Bookmark <i class="fa-regular fa-star"></i></button> : false}
                                </div>
                                <div className="row align-items-center">
                                    <div className="col-lg-2 col-md-4 col-sm-3">
                                        <div className="company-prof-img">
                                            {
                                                data.profile_pic === null ? <i class="fas fa-briefcase"></i> : <img src={`https://works-wise.demo-customproject.com/admin/backend/public/uploads/users/${data.profile_pic}`} alt />
                                            }
                                        </div>
                                    </div>
                                    <div className="col-lg-5 col-md-8 col-sm-9">
                                        <div className="company-mission">
                                            <h3>{data.company_name}</h3>
                                            <p>{data.introduce}</p>
                                        </div>
                                    </div>
                                    <div className="col-lg-5">
                                        <div className="company-prof-other-det">
                                            <div className="row">
                                                <div className="col-lg-6 col-md-6 col-sm-6">
                                                    <div className="comp-details">
                                                        <img src="images/company-founded.png" alt />
                                                        <div className="comp-det-moreinfo">
                                                            <h4>Founded on</h4>
                                                            <h6><Moment format="YYYY">{data.age}</Moment></h6>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-6 col-sm-6">
                                                    <div className="comp-details">
                                                        <img src="images/company-size.png" alt />
                                                        <div className="comp-det-moreinfo">
                                                            <h4>Company Size</h4>
                                                            <h5><span>{data.company_size}</span> Employees</h5>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="job-listing pb-10">
                <div className="container">
                    <div className="row main-job-listing-bg">
                        {
                            data.company_data === null ? false : <div className='col-lg-7'>
                                {
                                    Object.keys(JSON.parse(data.company_data)).map((item) => {
                                        if (JSON.parse(data.company_data)[item]) {
                                            return <div className='comp_data'>
                                                <h4>{item.replace("_", " ")}</h4>
                                                <p>{JSON.parse(data.company_data)[item]}</p>
                                            </div>
                                        }
                                    })
                                }
                            </div>
                        }
                        <div className="col">
                            <div className="job-display">
                                <div className="company-job-listing-sec">
                                    <div className="row">
                                        <h3>Job Posted by <span className="company-name">{data.company_name}</span></h3>
                                        <div className="col-lg-12">
                                            {
                                                jobs.map((item) => {
                                                    return <div className="row main-jp-item small-boxc">
                                                        <div className="col-lg-12">
                                                            <div className="job-post-item">
                                                                <h2>{item.title}</h2>
                                                                <div className="company-details-desc">
                                                                    <ul className="jobpost-tabs">
                                                                        <li>Posted Date<span className="posted-days-ago"><TimeAgo date={item.created_at} /></span>
                                                                        </li>
                                                                        <li>Location <span className="location-of-post">{item.location}</span></li>
                                                                        <li>Job Type<span className="job-type-post">{item.type}</span></li>
                                                                    </ul>
                                                                </div>
                                                                <div className="jobpost-content">
                                                                    <p>{item.short_discription}</p>
                                                                </div>
                                                                <div className="jobpost-act-btn">
                                                                    <Link to={`/job-description/${item.id}`} className="apply-job">Apply Now</Link>
                                                                    {/* <a href="#" className="save-job">Save<i className="fal fa-heart" /></a> */}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                })
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>

    )
}

export default Company_Profile