import { BrowserRouter, Routes, Route } from 'react-router-dom'
import Notfound from './pages/Notfound'
import Home from './pages/Home';
import Header from './components/Header';
import ScrollTop from './components/ScrollTop';
import About from './pages/About';
import Footer from './components/Footer';
import Job_Find from './pages/Job_Find';
import Hire_Talent from './pages/Hire_Talent';
import Jobs_Listing from './pages/Jobs_Listing';
import Candidate_Listing from './pages/Candidate_Listing';
import Pricing from './pages/Pricing';
import Contact from './pages/Contact';
import Job_Description from './pages/Job_Description';
import Candidate_Profile from './pages/Candidate_Profile';
import Company_Profile from './pages/Company_Profile';
import Sign_up from './pages/Sign_up';
import Login from './pages/Login';
import OTP_varify from './pages/OTP_varify';
import Forgot_Password from './pages/Forgot_Password';
import Reset_Password from './pages/Reset_Password';
import Update_Password from './pages/Update_Password';
import Company_Listing from './pages/Company_Listing';
import secureLocalStorage from 'react-secure-storage';
import { useEffect } from 'react';


function App() {

    useEffect(() => {
        secureLocalStorage.removeItem('forgot_pass');
    },[])

    return (
        <>
            <BrowserRouter>
                <Header />
                <ScrollTop />
                <Routes>
                    <Route path="/*" element={<Notfound />} />
                    <Route path="/" element={<Home />} />
                    <Route path="/about" element={<About />} />
                    <Route path="/find-jobs" element={<Job_Find />} />
                    <Route path="/find-talent" element={<Hire_Talent />} />
                    <Route path="/job-listing" element={<Jobs_Listing />} />
                    <Route path="/candidate-listing" element={<Candidate_Listing />} />
                    <Route path="/pricing" element={<Pricing />} />
                    <Route path="/contact" element={<Contact />} />
                    <Route path="/job-description/:id" element={<Job_Description />} />
                    <Route path="/candidate-profile/:id" element={<Candidate_Profile />} />
                    <Route path="/company-profile/:id" element={<Company_Profile />} />
                    <Route path="/sign-up" element={<Sign_up />} />
                    <Route path="/login" element={<Login />} />
                    <Route path="/otp-verify" element={<OTP_varify />} />
                    <Route path="/forgot-password" element={<Forgot_Password />} />
                    <Route path="/reset-password" element={<Reset_Password />} />
                    <Route path="/update-password" element={<Update_Password />} />
                    <Route path="/company-listing" element={<Company_Listing />} />
                </Routes>
                <Footer />
            </BrowserRouter>
        </>

    );
}

export default App;