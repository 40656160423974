import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

const Job_Find = () => {

    const [cms, cmsData] = useState(null);

    const getData = async () => {
        await axios.get('https://works-wise.demo-customproject.com:4002/get-cms/find_jobs').then((res) => {
            cmsData(res.data);
        })
    }

    useEffect(() => {
        getData();
    }, [])

    if (cms === null) {
        return <div className='Loading'></div>
    }

    return (
        <>
            <section className="find-job-header">
                <div className="container">
                    <div className="row d-flex">
                        <div className="col-lg-6">
                            <div className="find-job-desc">
                                <h2>{cms.section1.title}</h2>
                                <p>{cms.section1.text1}</p>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="fin-job-img">
                                <img
                                    src={`https://works-wise.demo-customproject.com/admin/backend/public/uploads/CMS/${cms.section1.image_path}`} alt />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="search_new_Sec">
                <div className="container">
                    <div className="row justify-content-end">
                        <div className="col-md-9  ">
                            <div className="main-search-sec">
                                <div className="orbit">
                                    <ul className="orbit-wrap">
                                        <li>
                                            <ul className="ring-0">
                                                <li><img src="images/orbit-icon-4.png" className="orbit-icon " alt /></li>
                                            </ul>
                                        </li>
                                        <li>
                                            <ul className="ring-1">
                                                <li><img src="images/orbit-icon-3.png" className="orbit-icon" alt /></li>
                                            </ul>
                                        </li>
                                        <li>
                                            <ul className="ring-2">
                                                <li><img src="images/orbit-icon-1.png" className="orbit-icon" alt /></li>
                                            </ul>
                                        </li>
                                        <li>
                                            <ul className="ring-3">
                                                <li><img src="images/orbit-icon-2.png" className="orbit-icon" alt /></li>
                                            </ul>
                                        </li>
                                    </ul>
                                </div>
                                <div className="search_bar_div">
                                    <h2>{cms.section2.title}
                                        {/* <span>that works for you</span> */}
                                    </h2>
                                    <div className="job-search">
                                        <form>
                                            <div className="row">
                                                <div className="col-lg-5">
                                                    <div className="job-search-input">
                                                        <input type="text" placeholder="Keywords/Job Title/Job Ref" />
                                                    </div>
                                                </div>
                                                <div className="col-lg-5">
                                                    <div className="job-search-loc">
                                                        <input type="text" placeholder="Location" />
                                                        <select name="job-distance" id="jobDistance">
                                                            <option value="5 miles">5 miles</option>
                                                            <option value="15 miles" selected>15 miles</option>
                                                            <option value="25 miles">25 miles</option>
                                                            <option value="35 miles">35 miles</option>
                                                            <option value="45 miles">45 miles</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="col-lg-2">
                                                    <div className="job-search-btn">
                                                        <button type="submit">Find job</button>
                                                    </div>
                                                </div>
                                                <div className="col-lg-7">
                                                    <div className="popular-cat">
                                                        <h4>Popular Categories</h4>
                                                        <ul>
                                                            <li><a href="#">SAP SI Consultant</a></li>
                                                            <li><a href="#">SAP SD Consultan</a></li>
                                                            <li><a href="#">Oracle SCM Consultant</a></li>
                                                            <li><a href="#">Azure Architect</a></li>
                                                            <li><a href="#">AWS Engineer</a></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="candidate-journey pt-5 pb-10">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
                                <li className="nav-item" role="presentation">
                                    <button className="nav-link active" id="pills-talentMarketplace-tab" data-bs-toggle="pill" data-bs-target="#pills-talentMarketplace" type="button" role="tab" aria-controls="pills-talentMarketplace" aria-selected="true">{cms.section2.button1}</button>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <button className="nav-link" id="pills-ProjectCatalog-tab" data-bs-toggle="pill" data-bs-target="#pills-ProjectCatalog" type="button" role="tab" aria-controls="pills-ProjectCatalog" aria-selected="false">{cms.section2.button2}</button>
                                </li>
                            </ul>
                            <div className="tab-content" id="pills-tabContent">
                                <div className="tab-pane fade show active" id="pills-talentMarketplace" role="tabpanel" aria-labelledby="pills-talentMarketplace-tab">
                                    <div className="row justify-content-between">
                                        <div className="col-lg-3">
                                            <div className="journey-item journey-item-after">
                                                <img src={`https://works-wise.demo-customproject.com/admin/backend/public/uploads/CMS/${cms.section2_tab1_box1.image_path}`} alt />
                                                <h4>{cms.section2_tab1_box1.title}</h4>
                                                <p>{cms.section2_tab1_box1.text1}</p>
                                            </div>
                                        </div>
                                        <div className="col-lg-3">
                                            <div className="journey-item journey-item-after2">
                                                <img src={`https://works-wise.demo-customproject.com/admin/backend/public/uploads/CMS/${cms.section2_tab1_box2.image_path}`} alt />
                                                <h4>{cms.section2_tab1_box2.title}</h4>
                                                <p>{cms.section2_tab1_box2.text1}</p>
                                            </div>
                                        </div>
                                        <div className="col-lg-3">
                                            <div className="journey-item">
                                                <img src={`https://works-wise.demo-customproject.com/admin/backend/public/uploads/CMS/${cms.section2_tab1_box3.image_path}`} alt />
                                                <h4>{cms.section2_tab1_box3.title}</h4>
                                                <p>{cms.section2_tab1_box3.text1}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row justify-content-between">
                                        <div className="col-lg-3">
                                            <div className="journey-item journey-item-after2">
                                                <img src={`https://works-wise.demo-customproject.com/admin/backend/public/uploads/CMS/${cms.section2_tab1_box4.image_path}`} alt />
                                                <h4>{cms.section2_tab1_box4.title}</h4>
                                                <p>{cms.section2_tab1_box4.text1}</p>
                                            </div>
                                        </div>
                                        <div className="col-lg-3">
                                            <div className="journey-item journey-item-after">
                                                <img src={`https://works-wise.demo-customproject.com/admin/backend/public/uploads/CMS/${cms.section2_tab1_box5.image_path}`} alt />
                                                <h4>{cms.section2_tab1_box5.title}</h4>
                                                <p>{cms.section2_tab1_box5.text1}</p>
                                            </div>
                                        </div>
                                        <div className="col-lg-3">
                                            <div className="journey-item">
                                                <img src={`https://works-wise.demo-customproject.com/admin/backend/public/uploads/CMS/${cms.section2_tab1_box6.image_path}`} alt />
                                                <h4>{cms.section2_tab1_box6.title}</h4>
                                                <p>{cms.section2_tab1_box6.text1}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="tab-pane fade" id="pills-ProjectCatalog" role="tabpanel" aria-labelledby="pills-ProjectCatalog-tab">
                                    <div className="row justify-content-between">
                                        <div className="col-lg-3">
                                            <div className="journey-item journey-item-after">
                                                <img src={`https://works-wise.demo-customproject.com/admin/backend/public/uploads/CMS/${cms.section2_tab2_box1.image_path}`} alt />
                                                <h4>{cms.section2_tab2_box1.title}</h4>
                                                <p>{cms.section2_tab2_box1.text1}</p>
                                            </div>
                                        </div>
                                        <div className="col-lg-3">
                                            <div className="journey-item journey-item-after2">
                                                <img src={`https://works-wise.demo-customproject.com/admin/backend/public/uploads/CMS/${cms.section2_tab2_box2.image_path}`} alt />
                                                <h4>{cms.section2_tab2_box2.title}</h4>
                                                <p>{cms.section2_tab2_box2.text1}</p>
                                            </div>
                                        </div>
                                        <div className="col-lg-3">
                                            <div className="journey-item">
                                                <img src={`https://works-wise.demo-customproject.com/admin/backend/public/uploads/CMS/${cms.section2_tab2_box3.image_path}`} alt />
                                                <h4>{cms.section2_tab2_box3.title}</h4>
                                                <p>{cms.section2_tab2_box3.text1}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row justify-content-center sec-line">
                                        <div className="col-lg-3">
                                            <div className="journey-item journey-item-after">
                                                <img src={`https://works-wise.demo-customproject.com/admin/backend/public/uploads/CMS/${cms.section2_tab2_box4.image_path}`} alt />
                                                <h4>{cms.section2_tab2_box4.title}</h4>
                                                <p>{cms.section2_tab2_box4.text1}</p>
                                            </div>
                                        </div>
                                        <div className="col-lg-3">
                                            <div className="journey-item">
                                                <img src={`https://works-wise.demo-customproject.com/admin/backend/public/uploads/CMS/${cms.section2_tab2_box5.image_path}`} alt />
                                                <h4>{cms.section2_tab2_box5.title}</h4>
                                                <p>{cms.section2_tab2_box5.text1}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="why-choose pb-10">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 centerCol">
                            <div className="wy-choose-heading">
                                <h2>{cms.section3.title} <span>{cms.section3.subtitle}</span></h2>
                            </div>
                        </div>
                    </div>
                    <div className="row d-flex">
                        <div className="col-lg-7">
                            <div className="why-choose-ww">
                                <p>{cms.section3.text1}</p>
                                <ul>
                                    <li>
                                        <div className="choose-point-icon">
                                            <i className="fal fa-award" />
                                        </div>
                                        <div className="choose-point-cntnt">
                                            <h6>{cms.section3_item1.title}</h6>
                                            <p>{cms.section3_item1.text1}</p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="choose-point-icon">
                                            <i className="fal fa-usd-circle" />
                                        </div>
                                        <div className="choose-point-cntnt">
                                            <h6>{cms.section3_item2.title}</h6>
                                            <p>{cms.section3_item2.text1}</p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="choose-point-icon">
                                            <i className="fal fa-shield-check" />
                                        </div>
                                        <div className="choose-point-cntnt">
                                            <h6>{cms.section3_item3.title}</h6>
                                            <p>{cms.section3_item3.text1}</p>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-5">
                            <div className="choose-img">
                                <img src={`https://works-wise.demo-customproject.com/admin/backend/public/uploads/CMS/${cms.section3.image_path}`} alt />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="are-you-ready">
                <div className="container">
                    <div className="row d-flex">
                        <div className="col-lg-6">
                            <div className="ready-img">
                                <img src={`https://works-wise.demo-customproject.com/admin/backend/public/uploads/CMS/${cms.section4.image_path}`} alt />
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="ready-hd">
                                <h2>{cms.section4.title}</h2>
                                <Link to="/sign-up" className="btn btn_badam">{cms.section4.button1}</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Job_Find