import React from 'react'
import axios from 'axios'
import { Link } from 'react-router-dom'
import { useProductContext } from '../context/productcontext'
import Benefits from '../components/Benefits';
import Faqs from '../components/Faqs';
import { useState, useEffect } from 'react';


const Pricing = () => {

    const [cms, cmsData] = useState(null);

    const getData = async () => {
        await axios.get('https://works-wise.demo-customproject.com:4002/get-cms/pricing').then((res) => {
            cmsData(res.data);
        });
    }

    useEffect(() => {
        getData();
    }, [])

    const { pricing, auth_data } = useProductContext();
    if (pricing === null || cms === null) {
        return <div className='Loading'></div>
    }
    console.log(auth_data)

    return (
        <>
            {/* <section className="main_slider inner-banner">
                <div id="carouselExampleControls" className="carousel slide" data-bs-ride="carousel">
                    <div className="carousel-inner">
                        <div className="banner-back">
                            <img src="images/banner-bottom.png" alt="img" />
                        </div>
                        <div className="carousel-item active">
                            <img src="images/banner-front.png" className="img-fluid banner-bg-img" alt="..." />
                            <div className="carousel-caption">
                                <div className="container">
                                    <div className="row">
                                        <div className=" col-sm-6 col-md-6 align-self-center">
                                            <div className="banner_text wow fadeInLeft" data-wow-duration="2s">
                                                <h1>Pricing
                                                </h1>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}
            <section className="our-prcing-sec hm-prc-sc-mnn">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="our-prcing-h wow fadeInUp">
                                <h2>{cms.pricing_heading.title}</h2>
                            </div>
                            <div className="row">
                                {
                                    pricing.map((prc) => {
                                        return <div className="col-lg-3 col-md-6 col-sm-6 mt-4">
                                            <div className="pricing-inner-txt">
                                                <h3 className="mb-1">{prc.title}</h3>
                                                <p>you can post <span>{prc.credit === '~' ? 'Unlimited' : prc.credit}</span> jobs each month</p>
                                                <h5 className="in-euro"><sup>$</sup>{prc.price}<sub> /{prc.duration == 0 ? "week" : (prc.duration == 1 ? "Month" : "Year")}</sub></h5>
                                                {
                                                    auth_data?.role == 0 ? <span  className="pric-equi">only for employers</span> : <a href='/dashboard/subscription/' className="pric-equi">{prc.buttonText}</a>
                                                }
                                            </div>
                                        </div>
                                    })
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Benefits />
            <Faqs />
        </>
    )
}

export default Pricing