import React from 'react'
import TimeAgo from './TimeAgo'
import { Link } from 'react-router-dom'
import { useProductContext } from '../context/productcontext';
import $ from 'jquery'
import secureLocalStorage from "react-secure-storage";
import axios from "axios";

const JobBox = ({ id, title, type, location, salary_min, salary_max, currency, skills, short_discription, company_name, company_id, created_at, profile_pic }) => {
    const { auth_data } = useProductContext();
    const saveJob = async (e) => {
        e.preventDefault();
        await axios.post(`https://works-wise.demo-customproject.com:4002/saveJob/`, { user_id: auth_data?.id, job_id: parseInt(id) }, { headers: { 'Auth': secureLocalStorage.getItem('accessToken') } }).then((res) => {
            if (res.data.status == '200') {
                $(e.target).closest('.job-post-item').find('.msg-box').removeClass('red')
                $(e.target).closest('.job-post-item').find('.msg-box').addClass('green').text(res.data.message);
                e.target.classList.remove('loadin');
                setTimeout(function () {
                    $(e.target).closest('.job-post-item').find('.msg-box').removeClass('green').text('');
                }, 2000)
            } else {
                e.target.classList.remove('loadin');
                $(e.target).closest('.job-post-item').find('.msg-box').removeClass('green')
                $(e.target).closest('.job-post-item').find('.msg-box').addClass('red').text(res.data.message);
            }
        })
    }

    return (
        <div className="row main-jp-item mb-4">
            <div className="col-lg-8 m-auto">
                <div className="job-post-item">
                    <Link to={`/job-description/${id}`}>
                        <h2>{title}</h2>
                    </Link>
                    <div className="company-details-desc">
                        <ul className="jobpost-tabs">
                            <li>Posted Date<span className="posted-days-ago"><TimeAgo date={created_at} /></span>
                            </li>
                            <li>Location <span className="location-of-post">{location}</span></li>
                            <li>Job Type<span className="job-type-post">{type}</span></li>
                        </ul>
                    </div>
                    <div className="jobpost-salary"><span>Salary</span> {salary_min} <sub>{currency}</sub> - {salary_max} <sub>{currency}</sub></div>
                    <div className="jobpost-content">
                        <p>{short_discription}</p>
                    </div>
                    <div className="jobpost-act-btn">
                        <Link to={`/job-description/${id}`} className="apply-job">Apply Now</Link>
                        {/* <a href="#" className="save-job">Save<i className="fal fa-heart" /></a> */}
                        {
                            auth_data === null ? <Link to="/login" className="save-job">Save</Link> : <a href="#" className="save-job" onClick={saveJob}>Save</a>
                        }
                    </div>
                    <div className='msg-box'></div>
                </div>
            </div>
            <div className="col-lg-4">
                <ul className="job-compny-info">
                    <li>
                        <div className="jp-compny-seemore">
                            <h5>Skills Required</h5>
                            <ul className="company-sec-cat">
                                {
                                    skills.split(',').map((skl) => {
                                        return <li><a href="javascript:;">{skl}</a></li>
                                    })
                                }
                            </ul>
                        </div>
                    </li>
                    <li className='mt-4'>
                        <h5>Posted By</h5>
                        <Link to={`/company-profile/${company_id}`} className='compnaylnks'>
                            {
                                profile_pic === null ? <i class="fas fa-briefcase"></i> : <img src={`https://works-wise.demo-customproject.com/admin/backend/public/uploads/users/${profile_pic}`} alt />
                            }
                            {company_name}</Link>
                    </li>
                </ul>
            </div>
        </div>
    )
}

export default JobBox