import React, { useEffect, useState } from 'react'
import { useProductContext } from '../context/productcontext'
import JobBox from '../components/JobBox';
import $ from 'jquery'

const getDaysDiffBetweenDates = (dateInitial, dateFinal) => (dateFinal - dateInitial) / (1000 * 3600 * 24);

const Jobs_Listing = () => {
    const { jobs, client, getJobs, isloading } = useProductContext();
    const [limit, setLimit] = useState(10);


    const [filter, setFilter] = useState({
        keywords: '',
        location: '',
        salary_min: '',
        salary_max: '',
        category: '',
        type: '',
        date: '',
        new: 'true',
    });

    const GetFilter = (e) => {
        if (e.target.name === 'salary') {
            let min = e.target.value.split('-')[0];
            let max = e.target.value.split('-')[1];
            setFilter((prev) => ({ ...prev, ['salary_min']: parseInt(min) }));
            setFilter((prev) => ({ ...prev, ['salary_max']: parseInt(max) }));
        } else {
            setFilter((prev) => ({ ...prev, [e.target.name]: e.target.value }));
        }
    }

    const OnSearch = async (e) => {
        e.preventDefault();
        let val = $('[name="location"]').val();
        setFilter((prev) => ({ ...prev, ['location']: val }));
        if (val !== '') {
            await getJobs(val);
            setLimit(10);
        } return false
    }

    const LoadMore = (e) => {
        e.preventDefault();
        setLimit(limit + 5)
    }

    // document.addEventListener("scroll", function (e) {
    //     var scrollTop = (window.pageYOffset !== undefined) ? window.pageYOffset : (document.documentElement || document.body.parentNode || document.body).scrollTop;
    //     let top = document.querySelector('.scrolltrue').offsetTop;
    //     if (scrollTop > top) {
    //         setLimit(limit + 5)
    //     }
    // });
    if (jobs === null || client === null) {
        return <div className='Loading'></div>
    }
    return (
        <div>
            <section className="search-job all-section mt-5">
                <div className="container">
                    <div className="row search-job-bg">
                        <div className="col-lg-12">
                            <form>
                                <div className="search-heading">
                                    <h3>Search 175,879 Jobs</h3>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        <div className="search-job-input">
                                            <input type="text" name="keywords" placeholder="Keywords" onChange={GetFilter} />
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="search-job-input">
                                            <select class="selectpicker with-border" onChange={OnSearch} defaultValue={client.country.name} data-size="7" title="Select Job Type" data-live-search="true" name="location">
                                                <option value="United States" hidden="" disabled="">United States</option>
                                                <option value="Argentina">Argentina</option>
                                                <option value="Armenia">Armenia</option>
                                                <option value="Aruba">Aruba</option>
                                                <option value="Australia">Australia</option>
                                                <option value="Austria">Austria</option>
                                                <option value="Azerbaijan">Azerbaijan</option>
                                                <option value="Bahamas">Bahamas</option>
                                                <option value="Bahrain">Bahrain</option>
                                                <option value="Bangladesh">Bangladesh</option>
                                                <option value="Barbados">Barbados</option>
                                                <option value="Belarus">Belarus</option>
                                                <option value="Belgium">Belgium</option>
                                                <option value="Belize">Belize</option>
                                                <option value="Benin">Benin</option>
                                                <option value="Bermuda">Bermuda</option>
                                                <option value="Bhutan">Bhutan</option>
                                                <option value="Bulgaria">Bulgaria</option>
                                                <option value="Burkina Faso">Burkina Faso</option>
                                                <option value="Burundi">Burundi</option>
                                                <option value="Cambodia">Cambodia</option>
                                                <option value="Cameroon">Cameroon</option>
                                                <option value="Canada">Canada</option>
                                                <option value="Cape Verde">Cape Verde</option>
                                                <option value="Cayman Islands">Cayman Islands</option>
                                                <option value="Colombia">Colombia</option>
                                                <option value="Comoros">Comoros</option>
                                                <option value="Congo">Congo</option>
                                                <option value="Cook Islands">Cook Islands</option>
                                                <option value="Costa Rica">Costa Rica</option>
                                                <option value="Côte d'Ivoire">Côte d'Ivoire</option>
                                                <option value="Croatia">Croatia</option>
                                                <option value="Cuba">Cuba</option>
                                                <option value="Curaçao">Curaçao</option>
                                                <option value="Cyprus">Cyprus</option>
                                                <option value="Czech Republic">Czech Republic</option>
                                                <option value="Denmark">Denmark</option>
                                                <option value="Djibouti">Djibouti</option>
                                                <option value="Dominica">Dominica</option>
                                                <option value="Dominican Republic">Dominican Republic</option>
                                                <option value="Ecuador">Ecuador</option>
                                                <option value="Egypt">Egypt</option>
                                                <option value="Guadeloupe">Guadeloupe</option>
                                                <option value="Guam">Guam</option>
                                                <option value="Guatemala">Guatemala</option>
                                                <option value="Guernsey">Guernsey</option>
                                                <option value="Guinea">Guinea</option>
                                                <option value="Guinea-Bissau">Guinea-Bissau</option>
                                                <option value="Guyana">Guyana</option>
                                                <option value="Haiti">Haiti</option>
                                                <option value="Honduras">Honduras</option>
                                                <option value="Hong Kong">Hong Kong</option>
                                                <option value="Hungary">Hungary</option>
                                                <option value="Iceland">Iceland</option>
                                                <option value="India">India</option>
                                                <option value="Indonesia">Indonesia</option>
                                                <option value="Norway">Norway</option>
                                                <option value="Oman">Oman</option>
                                                <option value="Pakistan">Pakistan</option>
                                                <option value="Palau">Palau</option>
                                                <option value="Panama">Panama</option>
                                                <option value="Papua">Papua New Guinea</option>
                                                <option value="Paraguay">Paraguay</option>
                                                <option value="Peru">Peru</option>
                                                <option value="Philippines">Philippines</option>
                                                <option value="Pitcairn">Pitcairn</option>
                                                <option value="Poland">Poland</option>
                                                <option value="Portugal">Portugal</option>
                                                <option value="Puerto Rico">Puerto Rico</option>
                                                <option value="Qatar">Qatar</option>
                                                <option value="Réunion">Réunion</option>
                                                <option value="Romania">Romania</option>
                                                <option value="Russian Federation">Russian Federation</option>
                                                <option value="Rwanda">Rwanda</option>
                                                <option value="Swaziland">Swaziland</option>
                                                <option value="Sweden">Sweden</option>
                                                <option value="Switzerland">Switzerland</option>
                                                <option value="Turkey">Turkey</option>
                                                <option value="Turkmenistan">Turkmenistan</option>
                                                <option value="Tuvalu">Tuvalu</option>
                                                <option value="Uganda">Uganda</option>
                                                <option value="Ukraine">Ukraine</option>
                                                <option value="United Kingdom">United Kingdom</option>
                                                <option value="United States">United States</option>
                                                <option value="Uruguay">Uruguay</option>
                                                <option value="Uzbekistan">Uzbekistan</option>
                                                <option value="Yemen">Yemen</option>
                                                <option value="Zambia">Zambia</option>
                                                <option value="Zimbabwe">Zimbabwe</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </section>
            <section className="job-listing pb-10">
                <div className="container">
                    <div className="row main-job-listing-bg">
                        <div className="col-lg-12">
                            <div className="job-display">
                                <div className="row">
                                    <div className="col">
                                        <div className="search-job-input">
                                            <select name="salary" onChange={GetFilter}>
                                                <option value disabled hidden>Salary</option>
                                                <option value="0 - 6000">0 - 6000</option>
                                                <option value="10000 - 15000">10000 - 15000</option>
                                                <option value="15000 - 20000">15000 - 20000</option>
                                                <option value="20000 - 25000">20000 - 25000</option>
                                                <option value="25000 - 30000">25000 - 30000</option>
                                                <option value="30000 - 35000">30000 - 35000</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="search-job-input" >
                                            <select name="type" onChange={GetFilter}>
                                                <option hidden="" disabled="">Select Job Type</option>
                                                <option value="Full Time">Full Time</option>
                                                <option value="Freelance">Freelance</option>
                                                <option value="Part Time">Part Time</option>
                                                <option value="Internship">Internship</option>
                                                <option value="Temporary">Temporary</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="search-job-input">
                                            <select name="category" onChange={GetFilter}>
                                                <option hidden="" value="">Select Industry</option>
                                                <option value="Accounting and Finance">Accounting and Finance</option>
                                                <option value="Clerical &amp; Data Entry">Clerical &amp; Data Entry</option>
                                                <option value="Counseling">Counseling</option>
                                                <option value="Court Administration">Court Administration</option>
                                                <option value="Human Resources">Human Resources</option>
                                                <option value="Investigative">Investigative</option>
                                                <option value="IT and Computers">IT and Computers</option>
                                                <option value="Law Enforcement">Law Enforcement</option>
                                                <option value="Management">Management</option>
                                                <option value="Miscellaneous">Miscellaneous</option>
                                                <option value="Public Relations">Public Relations</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="search-job-input">
                                            <select name="date" onChange={GetFilter}>
                                                <option value disabled hidden selected>Posted Date</option>
                                                <option value="0">Today</option>
                                                <option value="7">1 Week Ago</option>
                                                <option value="30">1 Month Ago</option>
                                                <option value="60">2 Months Ago</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div className="job-dis-header mt-4">
                                    <div className="jd-header-1">
                                        <h3><span className="filter-category">{filter.keywords}</span> Jobs In <span className="filter-country">{filter.location === '' ? client.country.name : filter.location}</span></h3>
                                        <p>Total <span className="ttl-filteredjobs">{jobs.length}</span> Jobs in your Region
                                        </p>
                                    </div>
                                    <div className="jd-header-2">
                                        <div className="jd-sortby">
                                            <label htmlFor="results-sortby">Sort by:</label>
                                            <select name="new" onChange={GetFilter} id="results-sortby">
                                                <option value="false">Latest</option>
                                                <option value="true" selected>Most Relevant</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div className="main-job-listing-sec">
                                    <div className="row">
                                        {isloading === true ? <div className='Loading p-5 h-auto col-lg-9'></div> : <div className="col-lg-9">
                                            {
                                                jobs.filter((item) => {
                                                    return item.title.toLowerCase().includes(filter.keywords.toLowerCase()) || item.skills.toLowerCase().includes(filter.keywords.toLowerCase());
                                                }).filter((item) => {
                                                    return item.salary_min > filter.salary_min || item.salary_max < filter.salary_max;
                                                }).filter((item) => {
                                                    if (filter.type === "") {
                                                        return item
                                                    } else {
                                                        return item.type.toLowerCase() === filter.type.toLowerCase()
                                                    }
                                                }).filter((item) => {
                                                    if (filter.category === "") {
                                                        return item
                                                    } else {
                                                        return item.category.toLowerCase() === filter.category.toLowerCase()
                                                    }
                                                }).filter((item) => {
                                                    if (filter.date === "") {
                                                        return item
                                                    } else {
                                                        return getDaysDiffBetweenDates(new Date(item.created_at), new Date()) > parseInt(filter.date);
                                                    }
                                                }).sort(function (a, b) {
                                                    if (filter.new !== 'true') {
                                                        var dateA = new Date(a.created_at), dateB = new Date(b.created_at)
                                                        return dateA - dateB
                                                    }
                                                }).map((job, key) => {
                                                    if (key < limit) {
                                                        return <JobBox {...job} />
                                                    }
                                                })
                                            }
                                            <div className='scrolltrue'></div>
                                            {/* {isloading === true ? <div className='Loading p-5 h-auto'></div> : false} */}
                                            {limit >= jobs.length ? <div className='notfound'>No More Jobs in your Region</div> : <div className='loadmore' onClick={LoadMore}>Load more jobs <i class="fa-regular fa-rotate-right"></i></div>}
                                        </div>}
                                        <div className="col-lg-3">
                                            <div className="jp-related-cat">
                                                <h3>Related Categories</h3>
                                                <ul>
                                                    <li><a href="#">HTML Development</a></li>
                                                    <li><a href="#">CSS Development</a></li>
                                                    <li><a href="#">Android Development</a></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>


    )
}

export default Jobs_Listing