import axios from 'axios'
import React, { useEffect, useState } from 'react'

function Faqs() {


    const [cms, cmsDeta] = useState(null);

    const getFaqs = async () => {
        await axios.get('https://works-wise.demo-customproject.com:4002/get-faqs').then((res) => {
            cmsDeta(res.data);
        })
    }

    useEffect(() => {
        getFaqs()
    }, [])

    // console.log(cms);

    if (cms === null) {
        return <div className='Loading'></div>
    }

    return (
        <div>
            <section className="find-talent-faqs">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-8 centerCol">
                            <div className="faq-bg">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="find-tal-heading">
                                            <h2>Frequently Asked Questions</h2>
                                        </div>
                                        {
                                            cms.map((item, key) => {
                                                return <div className="accordion" id="accordionExample">
                                                    <div className="accordion-item">
                                                        <h2 className="accordion-header" id={`headingOne${key}`}>
                                                            <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target={`#collapseOne${key}`} aria-expanded="true" aria-controls={`collapseOne${key}`}>
                                                                {item.question}
                                                            </button>
                                                        </h2>
                                                        <div id={`collapseOne${key}`} className="accordion-collapse collapse" aria-labelledby={`headingOne${key}`} data-bs-parent="#accordionExample">
                                                            <div className="accordion-body">{item.answer}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            })
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default Faqs
