import React from 'react'
import axios from 'axios';
import { omit } from 'lodash'
import PhoneInput from 'react-phone-number-input'
import { useEffect } from 'react';
import { useState } from 'react';
import { isValidPhoneNumber } from 'react-phone-number-input'
import $ from 'jquery'
const renderHTML = (escapedHTML) => React.createElement("div", { dangerouslySetInnerHTML: { __html: escapedHTML } });

const Contact = () => {

    const [cms, cmsData] = useState(null);

    const getData = async () => {
        await axios.get('https://works-wise.demo-customproject.com:4002/get-cms/contact').then((res) => {
            cmsData(res.data);
        })
    }

    useEffect(() => {
        getData();
    }, [])


    const [input, inputValue] = useState({
        name: "",
        email: "",
        phone: "",
        company_name: "",
        country: "",
        companysize: "",
        message: "",
        general: 0,
        project_management: 0,
        special_talent_request: 0
    });

    const setValue = (e) => {
        e.persist();
        inputValue((prev => ({ ...prev, [e.target.name]: e.target.value })))
        validate(e.target.name, e.target.value);
    }

    const setRadio = (e) => {
        if (e.target.checked === true) {
            inputValue((prev) => ({ ...prev, [e.target.name]: 1 }));
        } else {
            inputValue((prev) => ({ ...prev, [e.target.name]: 0 }));
        }
    }

    const setPhone = (e) => {
        inputValue((prev) => ({ ...prev, ['phone']: e }));
        validate('phone', e);
    }

    const formSubmit = async (e) => {
        e.preventDefault();
        e.target.classList.add('loadin');
        Object.keys(input).map((item) => {
            validate2(item, input[item]);
        })
        if (Object.keys(errors).length === 0 && Object.keys(err).length === 0) {
            await axios.post('https://works-wise.demo-customproject.com:4002/save-inquiry', input).then((response) => {
                if (response.data.status == '200') {
                    e.target.classList.remove('loadin');
                    $('.msg-box').removeClass('red')
                    $('.msg-box').addClass('green').text(response.data.message);
                } else {
                    e.target.classList.remove('loadin');
                    $('.msg-box').removeClass('green')
                    $('.msg-box').addClass('red').text(response.data.message);
                }
            });
        } else {
            e.target.classList.remove('loadin');
        }

    }

    const [errors, setErrors] = useState({});
    console.log(errors)
    const validate = (name, value) => {
        if (input.general == 0 && input.project_management == 0 && input.special_talent_request == 0) {
            setErrors({
                ...errors,
                'type': 'please select one of them'
            })
        } else {
            let newObj = omit(errors, "type");
            setErrors(newObj);
        }
        switch (name) {
            case 'name':
                if (value == undefined || value == '') {
                    setErrors({
                        ...errors,
                        name: 'Field cannot be empty'
                    })
                } else if (value.length <= 2) {
                    setErrors({
                        ...errors,
                        name: 'First name atleast have 3 letters'
                    })
                } else {
                    let newObj = omit(errors, "name");
                    setErrors(newObj);
                }
                break;
            case 'country':
                if (value == undefined || value == '') {
                    setErrors({
                        ...errors,
                        country: 'Field cannot be empty'
                    })
                } else {
                    let newObj = omit(errors, "country");
                    setErrors(newObj);
                }
                break;
            case 'message':
                if (value == undefined || value == '') {
                    setErrors({
                        ...errors,
                        message: 'Field cannot be empty'
                    })
                } else {
                    let newObj = omit(errors, "message");
                    setErrors(newObj);
                }
                break;
            case 'phone':
                if (value === null || value == undefined || value == '') {
                    setErrors({ ...errors, phone: 'Field cannot be empty' })
                }
                else if (!isValidPhoneNumber(value)) {
                    setErrors({ ...errors, phone: 'Invalid Phone Number' })
                } else {
                    let newObj = omit(errors, "phone");
                    setErrors(newObj);
                }
                break;
            case 'email':
                if (
                    !new RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/).test(value)
                ) {
                    setErrors({
                        ...errors,
                        email: 'Enter a valid business email address'
                    })
                } else {
                    let newObj = omit(errors, "email");
                    setErrors(newObj);
                }

                break;
            default:
                break;
        }
    }
    let err = {};
    const validate2 = (name, value) => {
        if (name == 'name') {
            if (value == undefined || value == '') {
                err = {
                    ...err,
                    name: 'Field cannot be empty'
                }
            } else if (value.length <= 2) {
                err = {
                    ...err,
                    name: 'First name atleast have 3 letters'
                }
            } else {
                let newObj = omit(err, "name");
                err = newObj;
            }
        }
        if (name == 'country') {
            if (value == undefined || value == '') {
                err = {
                    ...err,
                    country: 'Field cannot be empty'
                }
            } else {
                let newObj = omit(err, "country");
                err = newObj;
            }
        }

        if (name == 'message') {
            if (value == undefined || value == '') {
                err = {
                    ...err,
                    message: 'Field cannot be empty'
                }
            } else {
                let newObj = omit(err, "message");
                err = newObj;
            }
        }
        if (name == 'phone') {
            if (value === null || value == undefined || value == '') {
                console.log('asdasd')
                err = { ...err, phone: 'Field cannot be empty' }
            }
            else if (!isValidPhoneNumber(value)) {
                err = { ...err, phone: 'Invalid Phone Number' }
            } else {
                let newObj = omit(err, "phone");
                err = newObj;
            }
        }
        if (name == 'email') {
            if (
                !new RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/).test(value)
            ) {
                err = {
                    ...err,
                    email: 'Enter a valid business email address'
                }
            } else {
                let newObj = omit(err, "email");
                err = newObj;
            }
        }
        if (input.general == 0 && input.project_management == 0 && input.special_talent_request == 0) {
            err = {
                ...err,
                ['type']: 'please select one of them'
            }
        } else {
            let newObj = omit(err, "type");
            err = newObj;
        }
        setErrors(err);
    }


    if (cms === null) {
        return <div className='Loading'></div>
    }
    return (
        <section className="contact-sec">
            <div className="container">
                <div className="row">
                    <div className="col-md-7 centerCol">
                        <div className="sec-heading text-center wow fadeInDown">
                            <h4>{cms.contact_section.subtitle}</h4>
                            <h2>{cms.contact_section.title}</h2>
                            <p>{cms.contact_section.text1}</p>
                        </div>
                    </div>
                </div>
                <div className="row mt-5">
                    <div className="col-lg-8 col-sm-12 centerCol">
                        <div className="contactus-form">
                            <form>
                                <div className="row">
                                    <div className="col-lg-6">
                                        <input type="text" name="name" placeholder="Full Name" onChange={setValue} />
                                        {errors.name && <div className='errorr'>{errors.name}</div>}
                                    </div>
                                    <div className="col-lg-6">
                                        <input type="email" name="email" placeholder="Email" onChange={setValue} />
                                        {errors.email && <div className='errorr'>{errors.email}</div>}
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-6">
                                        <PhoneInput className="input" defaultCountry="US" international="false" placeholder="Enter Your Phone Number" onChange={setPhone} name="phone" />
                                        {/* <input type="tel" name="phone" placeholder="Phone Number" onChange={setValue} /> */}
                                        {errors.phone && <div className='errorr'>{errors.phone}</div>}
                                    </div>
                                    <div className="col-lg-6">
                                        <input type="text" name="company_name" placeholder="Company Name (optional)" onChange={setValue} />
                                        {errors.company_name && <div className='errorr'>{errors.company_name}</div>}
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-6">
                                        <select id="country" name="country" onChange={setValue}>
                                            <option value selected disabled hidden>Select Country</option>
                                            <option value="Afghanistan">Afghanistan</option>
                                            <option value="Åland Islands">Åland Islands</option>
                                            <option value="Albania">Albania</option>
                                            <option value="Algeria">Algeria</option>
                                            <option value="American Samoa">American Samoa</option>
                                            <option value="Andorra">Andorra</option>
                                            <option value="Angola">Angola</option>
                                            <option value="Anguilla">Anguilla</option>
                                            <option value="Antarctica">Antarctica</option>
                                            <option value="Antigua and Barbuda">Antigua and Barbuda</option>
                                            <option value="Argentina">Argentina</option>
                                            <option value="Armenia">Armenia</option>
                                            <option value="Aruba">Aruba</option>
                                            <option value="Australia">Australia</option>
                                            <option value="Austria">Austria</option>
                                            <option value="Azerbaijan">Azerbaijan</option>
                                            <option value="Bahamas">Bahamas</option>
                                            <option value="Bahrain">Bahrain</option>
                                            <option value="Bangladesh">Bangladesh</option>
                                            <option value="Barbados">Barbados</option>
                                            <option value="Belarus">Belarus</option>
                                            <option value="Belgium">Belgium</option>
                                            <option value="Belize">Belize</option>
                                            <option value="Benin">Benin</option>
                                            <option value="Bermuda">Bermuda</option>
                                            <option value="Bhutan">Bhutan</option>
                                            <option value="Bolivia">Bolivia</option>
                                            <option value="Bosnia and Herzegovina">Bosnia and Herzegovina</option>
                                            <option value="Botswana">Botswana</option>
                                            <option value="Bouvet Island">Bouvet Island</option>
                                            <option value="Brazil">Brazil</option>
                                            <option value="British Indian Ocean Territory">British Indian Ocean Territory
                                            </option>
                                            <option value="Brunei Darussalam">Brunei Darussalam</option>
                                            <option value="Bulgaria">Bulgaria</option>
                                            <option value="Burkina Faso">Burkina Faso</option>
                                            <option value="Burundi">Burundi</option>
                                            <option value="Cambodia">Cambodia</option>
                                            <option value="Cameroon">Cameroon</option>
                                            <option value="Canada">Canada</option>
                                            <option value="Cape Verde">Cape Verde</option>
                                            <option value="Cayman Islands">Cayman Islands</option>
                                            <option value="Central African Republic">Central African Republic</option>
                                            <option value="Chad">Chad</option>
                                            <option value="Chile">Chile</option>
                                            <option value="China">China</option>
                                            <option value="Christmas Island">Christmas Island</option>
                                            <option value="Cocos (Keeling) Islands">Cocos (Keeling) Islands</option>
                                            <option value="Colombia">Colombia</option>
                                            <option value="Comoros">Comoros</option>
                                            <option value="Congo">Congo</option>
                                            <option value="Congo, The Democratic Republic of The">Congo, The Democratic
                                                Republic of The</option>
                                            <option value="Cook Islands">Cook Islands</option>
                                            <option value="Costa Rica">Costa Rica</option>
                                            <option value="Cote D'ivoire">Cote D'ivoire</option>
                                            <option value="Croatia">Croatia</option>
                                            <option value="Cuba">Cuba</option>
                                            <option value="Cyprus">Cyprus</option>
                                            <option value="Czech Republic">Czech Republic</option>
                                            <option value="Denmark">Denmark</option>
                                            <option value="Djibouti">Djibouti</option>
                                            <option value="Dominica">Dominica</option>
                                            <option value="Dominican Republic">Dominican Republic</option>
                                            <option value="Ecuador">Ecuador</option>
                                            <option value="Egypt">Egypt</option>
                                            <option value="El Salvador">El Salvador</option>
                                            <option value="Equatorial Guinea">Equatorial Guinea</option>
                                            <option value="Eritrea">Eritrea</option>
                                            <option value="Estonia">Estonia</option>
                                            <option value="Ethiopia">Ethiopia</option>
                                            <option value="Falkland Islands (Malvinas)">Falkland Islands (Malvinas)</option>
                                            <option value="Faroe Islands">Faroe Islands</option>
                                            <option value="Fiji">Fiji</option>
                                            <option value="Finland">Finland</option>
                                            <option value="France">France</option>
                                            <option value="French Guiana">French Guiana</option>
                                            <option value="French Polynesia">French Polynesia</option>
                                            <option value="French Southern Territories">French Southern Territories</option>
                                            <option value="Gabon">Gabon</option>
                                            <option value="Gambia">Gambia</option>
                                            <option value="Georgia">Georgia</option>
                                            <option value="Germany">Germany</option>
                                            <option value="Ghana">Ghana</option>
                                            <option value="Gibraltar">Gibraltar</option>
                                            <option value="Greece">Greece</option>
                                            <option value="Greenland">Greenland</option>
                                            <option value="Grenada">Grenada</option>
                                            <option value="Guadeloupe">Guadeloupe</option>
                                            <option value="Guam">Guam</option>
                                            <option value="Guatemala">Guatemala</option>
                                            <option value="Guernsey">Guernsey</option>
                                            <option value="Guinea">Guinea</option>
                                            <option value="Guinea-bissau">Guinea-bissau</option>
                                            <option value="Guyana">Guyana</option>
                                            <option value="Haiti">Haiti</option>
                                            <option value="Heard Island and Mcdonald Islands">Heard Island and Mcdonald
                                                Islands</option>
                                            <option value="Holy See (Vatican City State)">Holy See (Vatican City State)
                                            </option>
                                            <option value="Honduras">Honduras</option>
                                            <option value="Hong Kong">Hong Kong</option>
                                            <option value="Hungary">Hungary</option>
                                            <option value="Iceland">Iceland</option>
                                            <option value="India">India</option>
                                            <option value="Indonesia">Indonesia</option>
                                            <option value="Iran, Islamic Republic of">Iran, Islamic Republic of</option>
                                            <option value="Iraq">Iraq</option>
                                            <option value="Ireland">Ireland</option>
                                            <option value="Isle of Man">Isle of Man</option>
                                            <option value="Israel">Israel</option>
                                            <option value="Italy">Italy</option>
                                            <option value="Jamaica">Jamaica</option>
                                            <option value="Japan">Japan</option>
                                            <option value="Jersey">Jersey</option>
                                            <option value="Jordan">Jordan</option>
                                            <option value="Kazakhstan">Kazakhstan</option>
                                            <option value="Kenya">Kenya</option>
                                            <option value="Kiribati">Kiribati</option>
                                            <option value="Korea, Democratic People's Republic of">Korea, Democratic
                                                People's Republic of</option>
                                            <option value="Korea, Republic of">Korea, Republic of</option>
                                            <option value="Kuwait">Kuwait</option>
                                            <option value="Kyrgyzstan">Kyrgyzstan</option>
                                            <option value="Lao People's Democratic Republic">Lao People's Democratic
                                                Republic</option>
                                            <option value="Latvia">Latvia</option>
                                            <option value="Lebanon">Lebanon</option>
                                            <option value="Lesotho">Lesotho</option>
                                            <option value="Liberia">Liberia</option>
                                            <option value="Libyan Arab Jamahiriya">Libyan Arab Jamahiriya</option>
                                            <option value="Liechtenstein">Liechtenstein</option>
                                            <option value="Lithuania">Lithuania</option>
                                            <option value="Luxembourg">Luxembourg</option>
                                            <option value="Macao">Macao</option>
                                            <option value="Macedonia, The Former Yugoslav Republic of">Macedonia, The Former
                                                Yugoslav Republic of</option>
                                            <option value="Madagascar">Madagascar</option>
                                            <option value="Malawi">Malawi</option>
                                            <option value="Malaysia">Malaysia</option>
                                            <option value="Maldives">Maldives</option>
                                            <option value="Mali">Mali</option>
                                            <option value="Malta">Malta</option>
                                            <option value="Marshall Islands">Marshall Islands</option>
                                            <option value="Martinique">Martinique</option>
                                            <option value="Mauritania">Mauritania</option>
                                            <option value="Mauritius">Mauritius</option>
                                            <option value="Mayotte">Mayotte</option>
                                            <option value="Mexico">Mexico</option>
                                            <option value="Micronesia, Federated States of">Micronesia, Federated States of
                                            </option>
                                            <option value="Moldova, Republic of">Moldova, Republic of</option>
                                            <option value="Monaco">Monaco</option>
                                            <option value="Mongolia">Mongolia</option>
                                            <option value="Montenegro">Montenegro</option>
                                            <option value="Montserrat">Montserrat</option>
                                            <option value="Morocco">Morocco</option>
                                            <option value="Mozambique">Mozambique</option>
                                            <option value="Myanmar">Myanmar</option>
                                            <option value="Namibia">Namibia</option>
                                            <option value="Nauru">Nauru</option>
                                            <option value="Nepal">Nepal</option>
                                            <option value="Netherlands">Netherlands</option>
                                            <option value="Netherlands Antilles">Netherlands Antilles</option>
                                            <option value="New Caledonia">New Caledonia</option>
                                            <option value="New Zealand">New Zealand</option>
                                            <option value="Nicaragua">Nicaragua</option>
                                            <option value="Niger">Niger</option>
                                            <option value="Nigeria">Nigeria</option>
                                            <option value="Niue">Niue</option>
                                            <option value="Norfolk Island">Norfolk Island</option>
                                            <option value="Northern Mariana Islands">Northern Mariana Islands</option>
                                            <option value="Norway">Norway</option>
                                            <option value="Oman">Oman</option>
                                            <option value="Pakistan">Pakistan</option>
                                            <option value="Palau">Palau</option>
                                            <option value="Palestinian Territory, Occupied">Palestinian Territory, Occupied
                                            </option>
                                            <option value="Panama">Panama</option>
                                            <option value="Papua New Guinea">Papua New Guinea</option>
                                            <option value="Paraguay">Paraguay</option>
                                            <option value="Peru">Peru</option>
                                            <option value="Philippines">Philippines</option>
                                            <option value="Pitcairn">Pitcairn</option>
                                            <option value="Poland">Poland</option>
                                            <option value="Portugal">Portugal</option>
                                            <option value="Puerto Rico">Puerto Rico</option>
                                            <option value="Qatar">Qatar</option>
                                            <option value="Reunion">Reunion</option>
                                            <option value="Romania">Romania</option>
                                            <option value="Russian Federation">Russian Federation</option>
                                            <option value="Rwanda">Rwanda</option>
                                            <option value="Saint Helena">Saint Helena</option>
                                            <option value="Saint Kitts and Nevis">Saint Kitts and Nevis</option>
                                            <option value="Saint Lucia">Saint Lucia</option>
                                            <option value="Saint Pierre and Miquelon">Saint Pierre and Miquelon</option>
                                            <option value="Saint Vincent and The Grenadines">Saint Vincent and The
                                                Grenadines</option>
                                            <option value="Samoa">Samoa</option>
                                            <option value="San Marino">San Marino</option>
                                            <option value="Sao Tome and Principe">Sao Tome and Principe</option>
                                            <option value="Saudi Arabia">Saudi Arabia</option>
                                            <option value="Senegal">Senegal</option>
                                            <option value="Serbia">Serbia</option>
                                            <option value="Seychelles">Seychelles</option>
                                            <option value="Sierra Leone">Sierra Leone</option>
                                            <option value="Singapore">Singapore</option>
                                            <option value="Slovakia">Slovakia</option>
                                            <option value="Slovenia">Slovenia</option>
                                            <option value="Solomon Islands">Solomon Islands</option>
                                            <option value="Somalia">Somalia</option>
                                            <option value="South Africa">South Africa</option>
                                            <option value="South Georgia and The South Sandwich Islands">South Georgia and
                                                The South Sandwich Islands</option>
                                            <option value="Spain">Spain</option>
                                            <option value="Sri Lanka">Sri Lanka</option>
                                            <option value="Sudan">Sudan</option>
                                            <option value="Suriname">Suriname</option>
                                            <option value="Svalbard and Jan Mayen">Svalbard and Jan Mayen</option>
                                            <option value="Swaziland">Swaziland</option>
                                            <option value="Sweden">Sweden</option>
                                            <option value="Switzerland">Switzerland</option>
                                            <option value="Syrian Arab Republic">Syrian Arab Republic</option>
                                            <option value="Taiwan">Taiwan</option>
                                            <option value="Tajikistan">Tajikistan</option>
                                            <option value="Tanzania, United Republic of">Tanzania, United Republic of
                                            </option>
                                            <option value="Thailand">Thailand</option>
                                            <option value="Timor-leste">Timor-leste</option>
                                            <option value="Togo">Togo</option>
                                            <option value="Tokelau">Tokelau</option>
                                            <option value="Tonga">Tonga</option>
                                            <option value="Trinidad and Tobago">Trinidad and Tobago</option>
                                            <option value="Tunisia">Tunisia</option>
                                            <option value="Turkey">Turkey</option>
                                            <option value="Turkmenistan">Turkmenistan</option>
                                            <option value="Turks and Caicos Islands">Turks and Caicos Islands</option>
                                            <option value="Tuvalu">Tuvalu</option>
                                            <option value="Uganda">Uganda</option>
                                            <option value="Ukraine">Ukraine</option>
                                            <option value="United Arab Emirates">United Arab Emirates</option>
                                            <option value="United Kingdom">United Kingdom</option>
                                            <option value="United States">United States</option>
                                            <option value="United States Minor Outlying Islands">United States Minor
                                                Outlying Islands</option>
                                            <option value="Uruguay">Uruguay</option>
                                            <option value="Uzbekistan">Uzbekistan</option>
                                            <option value="Vanuatu">Vanuatu</option>
                                            <option value="Venezuela">Venezuela</option>
                                            <option value="Viet Nam">Viet Nam</option>
                                            <option value="Virgin Islands, British">Virgin Islands, British</option>
                                            <option value="Virgin Islands, U.S.">Virgin Islands, U.S.</option>
                                            <option value="Wallis and Futuna">Wallis and Futuna</option>
                                            <option value="Western Sahara">Western Sahara</option>
                                            <option value="Yemen">Yemen</option>
                                            <option value="Zambia">Zambia</option>
                                            <option value="Zimbabwe">Zimbabwe</option>
                                        </select>
                                        {errors.country && <div className='errorr'>{errors.country}</div>}
                                    </div>
                                    <div className="col-lg-6">
                                        <select className="input" name="companysize" onChange={setValue}>
                                            <option hidden disabled selected>Size Of Company</option>
                                            <option value="10">10</option>
                                            <option value="10-50">10-50</option>
                                            <option value="50-500">50-500</option>
                                            <option value="500+">500+</option>
                                        </select>
                                        {errors.companysize && <div className='errorr'>{errors.companysize}</div>}
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-12">
                                        <textarea placeholder="Message here" name="message" onChange={setValue} />
                                        {errors.message && <div className='errorr'>{errors.message}</div>}
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-4">
                                        <div className="cntct-enquire">
                                            <input type="checkbox" name="general" id="general" onChange={setRadio} />
                                            <label htmlFor="general">General</label>
                                        </div>
                                    </div>
                                    <div className="col-lg-4">
                                        <div className="cntct-enquire">
                                            <input type="checkbox" name="project_management" id="pmanage" onChange={setRadio} />
                                            <label htmlFor="pmanage">Project Management</label>
                                        </div>
                                    </div>
                                    <div className="col-lg-4">
                                        <div className="cntct-enquire">
                                            <input type="checkbox" name="special_talent_request" id="st-req" onChange={setRadio} />
                                            <label htmlFor="st-req">Special Talent Request</label>
                                        </div>
                                    </div>
                                </div>
                                {errors.type && <div className='errorr mt-4'>{errors.type}</div>}
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="hire-talent-input">
                                            <button type="submit" onClick={formSubmit}>Send</button>
                                        </div>
                                        <div className='msg-box'></div>
                                        <div className='err-wrpper'>
                                            {/* {
                                                Object.keys(errors).length > 0 ? Object.keys(errors).map((err) => {
                                                    return <div className='errorr'>{renderHTML(errors[err])}</div>
                                                }) : false
                                            } */}
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </section>

    )
}

export default Contact